import React, { Component } from 'react';
import '../assets/scss/Table.scss';


export default class Table extends Component {
    constructor(props) {
        super(props);
        this.className = this.props.className ? this.props.className : '';
        this.empty = this.props.empty ? this.props.empty : '현재 등록된 게시물이 없습니다.';
    }

    render() {
        let n = 0;
        return (
            <table className={'db-table ' + this.className}>
                <thead>
                    <tr>
                    {
                        this.props.thead.map(col => {
                            return (
                                <th key={n++} width={col.width}>{col.text}</th>
                            )
                        })
                    }
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.tbody.map(row => {
                            let i = 0;
                            let colSpan = 1;
                            return (
                                <tr key={n++}>
                                    {
                                        row.map(col => {
                                            if (i >= 1 && row[i-1] === undefined) {
                                                colSpan++;
                                            }
                                            i++;
                                            if (col) {
                                                if (colSpan > 1) {
                                                    const temp = colSpan;
                                                    colSpan = 1;                                                    
                                                    return (
                                                        <td key={n++} colSpan={temp}>{col}</td>
                                                    )
                                                } else {
                                                    return (
                                                        <td key={n++}>{col}</td>
                                                    )
                                                }
                                            } else {
                                                return (
                                                    undefined
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    {
                        this.props.tbody.length === 0 &&
                        <tr><td colSpan={this.props.thead.length}><div >{this.empty}</div></td></tr> //className='empty'
                    }
                </tbody>
            </table>
        )
    }
}
