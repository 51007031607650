import React from 'react';
import { withRouter } from 'react-router';
import Tabs from '../components/Tabs';
import Table from '../components/Table';
import GuideStep from '../components/GuideStep';
import { Row, Col, Button } from 'reactstrap';
import '../assets/scss/Wallet.scss';
import { connect } from 'react-redux'
import moment from 'moment-timezone';

class Wallet extends React.Component {
    state = { depositHistory: undefined, withdrawHistory: undefined, selectedHistory: 'mixed', depositValue: "0", withdrawValue: "0" }

    constructor(props) {
        super(props);
        this.depositInput = React.createRef();
        this.withdrawInput = React.createRef();
    }

    componentDidMount() {
        if (localStorage.token === undefined || localStorage.token === null || localStorage.token === '') {
            window.location.href = '/Login';
        }

        if (this.props.user) {
            fetch('/api/user/deposit', {
                method: "GET",
                headers: {
                    "Authorization": 'Bearer ' + localStorage.token
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status === 'OK') {
                        let depositHistory = [];
                        res.data.forEach(item => {
                            depositHistory.push([item.depositID, moment(item.date).utc().format('yyyy-MM-DD HH:mm'), '입금', item.amount.toLocaleString(), item.statusText]);
                        });

                        this.setState({ depositHistory: depositHistory });
                        //do something
                    } else {
                        //do something
                    }
                });

            fetch('/api/user/withdraw', {
                method: "GET",
                headers: {
                    "Authorization": 'Bearer ' + localStorage.token
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status === 'OK') {
                        let withdrawHistory = [];
                        res.data.forEach(item => {
                            withdrawHistory.push([item.withdrawID, moment(item.date).utc().format('yyyy-MM-DD HH:mm'), '출금', item.amount.toLocaleString(), item.statusText]);
                        });

                        this.setState({ withdrawHistory: withdrawHistory });
                    } else {
                        //do something
                    }
                });
        }
    }

    render() {
        var mode = this.props.match.params.walletMode;
        return (
            <div className="container wallet">
                <Tabs tabData={this.walletTabs(this.props)} path={this.props.match.path} wildCard=':walletMode' selected={mode} />
                {this.renderWallet(mode, this.props, this.state, this.setState)}
            </div>
        );
    }

    setHistory = (selected, setState) => {
        this.setState({ selectedHistory: selected });
    }

    addWithdrawMoney = (amount) => {
        if (amount === -1) {
            this.withdrawInput.current.value = this.props.user.cash;
            return;
        }
        if (amount === 0) {
            this.withdrawInput.current.value = 0;
            return;
        }
        let num = parseInt(this.withdrawInput.current.value) + amount;
        if (num > this.props.user.cash) {
            num = this.props.user.cash;
        }
        this.withdrawInput.current.value = num;
    }

    addDepositMoney = (amount) => {
        if (amount === 0) {
            this.depositInput.current.value = 0;
            return;
        }
        let num = parseInt(this.depositInput.current.value) + amount;
        // if (num > this.props.user.cash) {
        //     num = this.props.user.cash;
        // }
        this.depositInput.current.value = num;
    }


    requestWithdraw = (event) => {
        event.target.disabled = 'disabled';
        fetch('/api/user/withdraw', {
            method: "POST",
            headers: {
                "Content-Type": 'application/json',
                "Authorization": 'Bearer ' + localStorage.token
            },
            body: JSON.stringify({
                amount: parseInt(this.withdrawInput.current.value)
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === 'OK') {
                    window.location.href = '/Wallet/History';
                    //do something
                } else {
                    alert('출금 신청에 실패했습니다.');
                    event.target.disabled = '';
                    //do something
                }
            })
            .catch(err => {
                alert('출금 신청 도중에 오류가 발생했습니다.');
                event.target.disabled = '';
            });
    }

    requestDeposit = (event) => {
        event.target.disabled = 'disabled';
        fetch('/api/user/deposit', {
            method: "POST",
            headers: {
                "Content-Type": 'application/json',
                "Authorization": 'Bearer ' + localStorage.token
            },
            body: JSON.stringify({
                amount: parseInt(this.depositInput.current.value)
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === 'OK') {
                    window.location.href = '/Wallet/History';
                    //do something
                } else {
                    alert('입금 신청에 실패했습니다.');
                    event.target.disabled = '';
                    //do something
                }
            })
            .catch(err => {
                alert('입금 신청 도중에 오류가 발생했습니다.');
                event.target.disabled = '';
            });
    }

    walletTabs = () => {
        return {
            "Deposit": {
                title: "입금신청",
                steps: [
                    {
                        step: 'STEP 1',
                        stepName: '입금 이체계좌',
                        content: <div><div>
                            <div className='s-head'>예금주 &amp; 입금계좌</div>
                            <div className='s-body'>
                                입금신청 후 1:1문의를 통해 입금계좌를 발급 받으십시오.<br />1:1문의를 통해 [계좌확인] 후 입금해주세요.
                            </div></div>
                        </div>,
                        description: <p></p>
                    },
                    {
                        step: 'STEP 2',
                        stepName: '입금금액',
                        content: <div><div>
                            <div className='button-area'>
                                <button className='money-btn' onClick={() => { this.addDepositMoney(10000) }}>+10,000</button>
                                <button className='money-btn' onClick={() => { this.addDepositMoney(100000) }}>+100,000</button>
                                <button className='money-btn' onClick={() => { this.addDepositMoney(500000) }}>+500,000</button>
                                <button className='money-btn' onClick={() => { this.addDepositMoney(1000000) }}>+1,000,000</button>
                                <button className='money-btn' onClick={() => { this.addDepositMoney(5000000) }}>+5,000,000</button>
                                <button className='money-btn' onClick={() => { this.addDepositMoney(10000000) }}>+10,000,000</button>
                                <button className='money-btn' onClick={() => { this.addDepositMoney(0) }}>정정</button>
                            </div>
                            <div className='input-area'>
                                <input type='text' name='amount' ref={this.depositInput} defaultValue="0" onChange={(event) => { this.setState({ depositValue: event.target.value }) }} />
                                <div className='won'>원</div>
                            </div></div>
                        </div>,
                        description: <div className='ok-btn-area'><Button className='ok-btn' color='danger' onClick={this.requestDeposit}>입금신청</Button></div>
                    }
                ]
            },
            "Withdraw": {
                title: "출금신청",
                steps: [
                    {
                        step: 'STEP 1',
                        stepName: '내 계좌정보',
                        content: <div>
                            <div>
                                <div className='s-head'>예금주</div>
                                <div className='s-body'>
                                    {
                                        this.props.user !== undefined ?
                                            this.props.user.name
                                            :
                                            ""
                                    }
                                </div>
                            </div>

                            <div>
                                <div className='s-head'>입금계좌</div>
                                <div className='s-body'>
                                    {
                                        this.props.user !== undefined ?
                                            this.props.user.bank
                                            :
                                            ""
                                    } / {
                                        this.props.user !== undefined ?
                                            this.props.user.account
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>,
                        description: <p></p>
                    },
                    {
                        step: 'STEP 2',
                        stepName: '출금금액',
                        content: <div><div>
                            <div className='button-area'>
                                <button className='money-btn' onClick={() => { this.addWithdrawMoney(10000) }}>+10,000</button>
                                <button className='money-btn' onClick={() => { this.addWithdrawMoney(100000) }}>+100,000</button>
                                <button className='money-btn' onClick={() => { this.addWithdrawMoney(500000) }}>+500,000</button>
                                <button className='money-btn' onClick={() => { this.addWithdrawMoney(1000000) }}>+1,000,000</button>
                                <button className='money-btn' onClick={() => { this.addWithdrawMoney(5000000) }}>+5,000,000</button>
                                <button className='money-btn' onClick={() => { this.addWithdrawMoney(10000000) }}>+10,000,000</button>
                                <button className='money-btn' onClick={() => { this.addWithdrawMoney(-1) }}>최대</button>
                                <button className='money-btn' onClick={() => { this.addWithdrawMoney(0) }}>정정</button>
                            </div>
                            <div className='input-area'>
                                <div className='amount-area'>
                                    <input type='text' name='amount' ref={this.withdrawInput} defaultValue="0" onChange={(event) => { this.setState({ withdrawValue: event.target.value }) }} />
                                    <div className='won'>원</div>
                                </div>
                                <p>
                                    최소 출금 금액 10,000원 / 1일 출금한도 900만원 / 출금시간 평일 AM 09:00 ~ PM 07:00
                                </p>
                                {/* <div className='after-amount'>
                                    <div className='s-head'>출금 후 자산</div>
                                    <div className='s-body'>
                                        1,000,000원
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        </div>,
                        description: <div className='ok-btn-area'><Button className='ok-btn' color='info' onClick={this.requestWithdraw}>출금신청</Button></div>
                    }
                ]
            },
            "History": {
                title: "입출금내역"
            }
        };
    };

    Deposit = () => {
        return (
            <Row className='deposit'>
                <Col xs='12' className='total-amount'>
                    <Row>
                        <Col xs='12' sm='6'>
                            <div className='s-head'>총 보유 자산</div>
                            <div className='s-body'>{
                                this.props.user && this.props.user.cash !== undefined ?
                                    this.props.user.cash.toLocaleString()
                                    :
                                    0
                            } 원</div>
                        </Col>
                        <Col xs='12' sm='6'>
                            <div className='s-head'>사용가능 자산</div>
                            <div className='s-body available-amount'>{
                                this.props.user && this.props.user.cash !== undefined ?
                                    this.props.user.cash.toLocaleString()
                                    :
                                    0
                            } 원</div>
                        </Col>
                    </Row>
                </Col>
                {
                    this.walletTabs()['Deposit'].steps.map(step => {
                        return (
                            <GuideStep key={step.step} step={step.step} stepName={step.stepName} content={step.content} description={step.description} />
                        );

                    })
                }
                <Col xs='12'><h5 className="caution-title">※ 입금 시 주의 사항 반드시 읽어주세요 !!</h5><ul className="caution">
                    <li>1. 입금하시기 전에 항상 계좌확인을 부탁드리며, 계좌확인을 안하시면 입금신청을 하실 수 없습니다.</li>
                    <li>2. 입금신청 후 30분 이내에 실제 입금이 되지 않는 경우에는 신청하신 내용이 자동 삭제(취소)됩니다.</li>
                    <li>3. 입금자명(입금통장명의자)/입금신청금액/실제입금금액이 일치하면 입금 후 신속히 처리가 됩니다.</li>
                    <li>4. 입금신청은 신청하신 내용이 완료되지 않거나 삭제(취소)되지 않은 상태에서는 중복신청 또는 추가신청이 불가 합니다.</li>
                    <li>5. 수표입금 시 입금 처리가 되지않습니다.</li>
                    <li>6. 입금 가능 시간은 평일 AM 09 : 00 ~ PM 07 : 00까지 신청가능하며 연중무휴입니다.</li>
                </ul></Col>
            </Row>
        );
    };

    Withdraw = () => {
        return (
            <Row className='withdraw'>
                <Col xs='12' className='total-amount'>
                    <Row>
                        <Col xs='12' sm='6'>
                            <div className='s-head'>총 보유 자산</div>
                            <div className='s-body'>{
                                this.props.user && this.props.user.cash !== undefined ?
                                    this.props.user.cash.toLocaleString()
                                    :
                                    0
                            } 원</div>
                        </Col>
                        <Col xs='12' sm='6'>
                            <div className='s-head'>사용가능 자산</div>
                            <div className='s-body available-amount'>{
                                this.props.user && this.props.user.cash !== undefined ?
                                    this.props.user.cash.toLocaleString()
                                    :
                                    0
                            } 원</div>
                        </Col>
                    </Row>
                </Col>
                {
                    this.walletTabs()['Withdraw'].steps.map(step => {
                        return (
                            <GuideStep key={step.step} step={step.step} stepName={step.stepName} content={step.content} description={step.description} />
                        );
                    })
                }
                <Col xs='12'><h5 className="caution-title">※ 입금 시 주의 사항 반드시 읽어주세요 !!</h5><ul className="caution">
                    <li>1. 입금하시기 전에 항상 계좌확인을 부탁드리며, 계좌확인을 안하시면 입금신청을 하실 수 없습니다.</li>
                    <li>2. 입금신청 후 30분 이내에 실제 입금이 되지 않는 경우에는 신청하신 내용이 자동 삭제(취소)됩니다.</li>
                    <li>3. 입금자명(입금통장명의자)/입금신청금액/실제입금금액이 일치하면 입금 후 신속히 처리가 됩니다.</li>
                    <li>4. 입금신청은 신청하신 내용이 완료되지 않거나 삭제(취소)되지 않은 상태에서는 중복신청 또는 추가신청이 불가 합니다.</li>
                    <li>5. 수표입금 시 입금 처리가 되지않습니다.</li>
                    <li>6. 입금 가능 시간은 평일 AM 09 : 00 ~ PM 07 : 00까지 신청가능하며 연중무휴입니다.</li>
                </ul></Col>
            </Row>
        );
    };

    thead = [
        { text: '번호', width: '10%' },
        { text: '요청일시', width: '20%' },
        { text: '구분', width: '30%' },
        { text: '금액', width: '20%' },
        { text: '상태', width: '10%' }
    ];

    History = () => {
        return (
            <Row className='history'>
                <Col xs='12' className='total-amount'>
                    <Row>
                        <Col xs='12' sm='6'>
                            <div className='s-head'>총 보유 자산</div>
                            <div className='s-body'>{
                                this.props.user && this.props.user.cash !== undefined ?
                                    this.props.user.cash.toLocaleString()
                                    :
                                    0
                            } 원</div>
                        </Col>
                        <Col xs='12' sm='6'>
                            <div className='s-head'>사용가능 자산</div>
                            <div className='s-body available-amount'>{
                                this.props.user && this.props.user.cash !== undefined ?
                                    this.props.user.cash.toLocaleString()
                                    :
                                    0
                            } 원</div>
                        </Col>
                    </Row>
                </Col>
                <Col xs='12' className='button-area'>
                    <button className={'history-btn ' + (this.state.selectedHistory === 'mixed' ? 'selected' : '')} onClick={() => { this.setHistory('mixed') }}>전체</button>
                    <button className={'history-btn ' + (this.state.selectedHistory === 'deposit' ? 'selected' : '')} onClick={() => { this.setHistory('deposit') }}>입금</button>
                    <button className={'history-btn ' + (this.state.selectedHistory === 'withdraw' ? 'selected' : '')} onClick={() => { this.setHistory('withdraw') }}>출금</button>
                </Col>
                <Col xs='12'>
                    {
                        this.renderHistoryTable()
                    }
                </Col>
            </Row>
        );
    };

    orderByDateDesc = (a, b) => {
        if (a[1] < b[1]) {
            return 1;
        }
        if (a[1] > b[1]) {
            return -1;
        }
        // a must be equal to b
        return 0;
    };

    renderHistoryTable = () => {
        let depositHistory = this.state.depositHistory ? this.state.depositHistory : [];
        let withdrawHistory = this.state.withdrawHistory ? this.state.withdrawHistory : [];
        if (this.state.selectedHistory === 'mixed') {
            let mixedHistory = [];
            mixedHistory = mixedHistory.concat(depositHistory);
            mixedHistory = mixedHistory.concat(withdrawHistory);
            mixedHistory = mixedHistory.sort(this.orderByDateDesc);
            return <Table thead={this.thead} tbody={mixedHistory} />
        } else if (this.state.selectedHistory === 'deposit') {
            return <Table thead={this.thead} tbody={depositHistory} />
        } else {
            return <Table thead={this.thead} tbody={withdrawHistory} />
        }
    }

    renderWallet = (mode) => {
        switch (mode) {
            case 'Deposit':
                return this.Deposit();
            case 'Withdraw':
                return this.Withdraw();
            case 'History':
                return this.History();
            default:
                return <p>존재하지 않는 페이지입니다.</p>;
        }
    };
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Wallet))