import React, { Component } from 'react';
import socketio from 'socket.io-client';
import { createChart } from 'lightweight-charts';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'


class CandleChart extends Component {
    state = { open: 0, high: 0, low: 0, close: 0, interval: 1, last10Data: [] };

    // constructor(props) {
    //     super(props);
    // }

    symbolFixed = {
        BTCUSDT: 2,
        ETHUSDT: 2,
        XAUUSD: 3,
        GBPAUD: 5,
        KOSPI200: 2,
        NDX: 2
    };

    componentDidMount() {
        this.initChart();
        this.boxResize();
        window.addEventListener('resize', this.boxResize);
        setTimeout(() => {
            this.boxResize();
        }, 500);

        setTimeout(() => {
            this.boxResize();
        }, 1000);

        setTimeout(() => {
            this.boxResize();
        }, 2000);
    }

    componentDidUpdate() {
        // if (this.props.interval !== this.state.interval) {
        //     // //nolog//console.log(this.props.symbol, this.props.interval);
        //     this.socket.close();
        //     this.socket = undefined;
        //     this.initChart();
        //     this.setState({ interval: this.props.interval });
        // }
    }

    logMapElements = (value, key, map) => {
        var open_price = map.get(key).open;
        var high_price = map.get(key).high;
        var low_price = map.get(key).low;
        var close_price = map.get(key).close;
        this.setState( { open: open_price, high: high_price, low: low_price, close: close_price } );
    }

    toTimestamp = (strDate) => {
        var datum = Date.parse(strDate);
        return datum / 1000;
    };

    boxResize = () => {
        if (document.querySelector('.box-chart')) {
            const width = document.querySelector('.box-chart').getClientRects()[0].width - 10;
            const height = document.querySelector('.box-chart').getClientRects()[0].height - 10;
            if (this.chart) {
                this.chart.applyOptions({
                    width: width,
                    height: height
                });
            }
        }
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.boxResize);
    }

    initChart() {
        //nolog//console.log(this.props.symbol, this.props.interval);
        const width = document.querySelector('.box-chart').getClientRects()[0].width - 10;
        const height = document.querySelector('.box-chart').getClientRects()[0].height - 10;
        

        this.chart = createChart(document.getElementById('chart-area'), {
            width: width,
            height: height,
            "autosize": true,
            title: 'Graph',//#363c4e
            crosshair: {
                // vertLine: {
                //     color: '#6A5ACD',
                //     width: 1,
                //     style: 1,
                //     visible: true,
                //     labelVisible: true,
                // },
                // horzLine: {
                //     color: '#6A5ACD',
                //     width: 0.5,
                //     style: 1,
                //     visible: true,
                //     labelVisible: true,
                // },
                // mode: 0,
            },
            // watermark: {
            //     color: 'red',
            //     visible: true,
            //     text: '',
            //     fontSize: 12,
            //     horzAlign: 'right',
            //     vertAlign: 'top',
            // },
            localization: {
                dateFormat: 'yyyy-MM-dd',
                locale: 'ko-KR',
            },
            priceScale: {
                position: 'right',
                mode: 1,
                autoScale: true,
                invertScale: false,
                alignLabels: true,
                borderVisible: true,
                borderColor: '#777777',
                scaleMargins: {
                    top: 0.10,
                    bottom: 0.10,
                },
                
            },
            layout: {
                backgroundColor: '#161a23',
                textColor: '#fff',
                lineColor: '#fff',
                // textColor: '#161a23'
            },
            timeScale: {
                rightOffset: 5,
                barSpacing: 15,
                fixLeftEdge: false,
                lockVisibleTimeRangeOnResize: true,
                rightBarStaysOnScroll: true,
                borderVisible: true,
                borderColor: '#777777',
                visible: true,
                timeVisible: true,
                secondsVisible: false
            },
            grid: {
                vertLines: {
                    color: '#363c4e'
                },
                horzLines: {
                    color: '#363c4e'
                }
            },
            paneWidgets: true
        });
        const chart = this.chart;

        const candleSeries = chart.addCandlestickSeries({
            upColor: '#f14f57',
            downColor: '#3488db',
            borderVisible: true,
            wickVisible: true,
            borderUpColor: '#f14f57',
            borderDownColor: '#3488db',
            wickUpColor: "#f14f57",
            wickDownColor: "#3488db"
        });

        candleSeries.applyOptions({
            lastValueVisible: true,
            overlay: true,

            priceFormat: {
                type: 'custom',
                minMove: 0.000001,
                formatter: function (price) {
                    return price.toFixed(this.symbolFixed[this.props.symbol]);
                }.bind(this),
            },
        });

        chart.subscribeCrosshairMove((param) => {
            param.seriesPrices.forEach(this.logMapElements)
        });

        this.socket = socketio.connect('/');
        const socket = this.socket;

        socket.on('connect', (arg) => {
            ////nolog//console.log('server connected');
            // //nolog//console.log(this);
            socket.emit('Get24H', { symbol: this.props.symbol, interval: parseInt(this.props.interval) });
        });

        socket.on('TickUpdated', (res) => {
            if (res.symbol !== this.props.symbol)
                return;
            if (res.interval !== this.props.interval)
                return;
            ////nolog//console.log(res);
            var item = res.data[0];

            candleSeries.update({
                time: this.toTimestamp(item.CloseTime),// + 0.001,
                open: item.Open,
                high: item.High,
                low: item.Low,
                close: item.Close,
            });
            
            // //nolog//console.log(last10Data);

            // this.setState({ last10Data:  });
            ////nolog//console.log(toTimestamp(item.CloseTime) + 0.001);
        });

        socket.on('Get24H', (res) => {
            if (res.symbol !== this.props.symbol)
                return;
            ////nolog//console.dir(res);
            //return;
            const data = [];

            for(var i = 0; i < res.data.length; i++) {
                var item = res.data[i];
                data.push({
                    time: this.toTimestamp(item.CloseTime),// + 0.001, //time: toTimestamp(item.CloseTime), //time: (item.closeTime + '').replace('T', ' ').replace(':00.000Z', ''),// + 0.001
                    open: item.Open,
                    high: item.High,
                    low: item.Low,
                    close: item.Close,
                });
            }

            if (data.length > 0) {
                this.setState( { open: data[data.length - 1].open, high: data[data.length - 1].high, low: data[data.length - 1].low, close: data[data.length - 1].close } );
                // //nolog//console.log(data.slice(-10));
                // this.setState( { last10Data: data.slice(-10) } );
            }
            
            
            candleSeries.setData(data);
        });
    }
/**
 * 
 *             upColor: '#f14f57',
            downColor: '#3488db',
 */
    render() {
        return (
            <div key={this.props.symbol + '_' + this.props.interval} style={{
                position: 'relative'
            }}>
                <div id='chart-area'>

                </div>
                <span className="chart-price pl10" style={{
                    position: 'absolute',
                    left: 10,
                    top: 5,
                    zIndex: 999,
                    color: 'white',
                    fontWeight: 100
                }}>
                    {this.props.symbol}, {this.props.interval}&nbsp;&nbsp;&nbsp;&nbsp;
                    시 <span style={{
                        color: this.state.close > this.state.open ? '#f14f57' : '#3488db'
                    }}>{this.state.open}</span>&nbsp;
                    고 <span style={{
                        color: this.state.close > this.state.open ? '#f14f57' : '#3488db'
                    }}>{this.state.high}</span>&nbsp;
                    저 <span style={{
                        color: this.state.close > this.state.open ? '#f14f57' : '#3488db'
                    }}>{this.state.low}</span>&nbsp;
                    종 <span style={{
                        color: this.state.close > this.state.open ? '#f14f57' : '#3488db'
                    }}>{this.state.close}</span>
                </span>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CandleChart))