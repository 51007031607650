import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import {
  BrowserRouter as Router
} from "react-router-dom";
import Route from './Route';
import React from 'react';

const appName = 'S&P INDEX';
const logo = '/img/logo.png';

export default class App extends React.Component {

  render() {
    return (
      <Router>
        <div className="App">
          <div className='container'>
            <Header logo={logo} appName={appName} />
          </div>
          <Route />
          <Footer logo={logo} appName={appName} />
        </div>
      </Router>
    )
  }rpwhg
}


// export default App;
