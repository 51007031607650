import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row } from 'reactstrap';
import GuideStep from '../components/GuideStep';
import Tabs from '../components/Tabs';
import '../assets/scss/Guide.scss';

const guideSteps = {
    "Wallet": {
        title: "입출금 신청방법",
        steps: [
            {
                step: 'STEP 1',
                stepName: '입금 신청',
                content: <img src="/img/img_info_1_01.jpg" alt="입금 이체계좌" />,
                description: <p>상단 메뉴에서 <strong>[입출금신청&gt;입금 신청]</strong>을 클릭합니다.<br />계좌정보는 입금신청 후 확인이 가능합니다.</p>
            },
            {
                step: 'STEP 2',
                stepName: '입금액 기입 및 신청',
                content: <img src="/img/img_info_1_02.jpg" alt="입금액 기입 및 신청" />,
                description: <p>우선 입금하실 금액을 입력하고 <strong>[입금신청]</strong>을 클릭합니다.<br />입금신청과 함께 <strong>[입출금내역]</strong>에 회원님이 신청한 내역이 나타납니다.<br />해당 내역에서 <strong>[계좌정보]</strong>를 클릭합니다.<br />입금하실 계좌정보를 확인 후 입금합니다.<br />관리자가 확인후 5 ~ 30분 내로 입금처리가 완료됩니다.</p>
            },
            {
                step: 'STEP 3',
                stepName: '출금 신청',
                content: <img src="/img/img_info_1_03.jpg" alt="출금 신청" />,
                description: <p>상단 메뉴에서 <strong>[입출금신청 &gt; 출금 신청]</strong>을 클릭합니다.<br />인의 등록된 계좌 정보를 다시한번 확인합니다.</p>
            },
            {
                step: 'STEP 4',
                stepName: '출금액 기입 및 신청',
                content: <img src="/img/img_info_1_04.jpg" alt="출금액 기입 및 신청" />,
                description: <p>보유 자산을 확인 후 출금하실 금액을 입력하고 <strong>[출금신청]</strong>을 클릭합니다.<br />최소 출금신청 금액은 10,000원부터입니다.<br />출금 시 아래의 주의 사항 및 출금 시간을 받드시 숙지하시고 이용바랍니다.</p>
            },

        ],
        selected: 'selected'
    },
    "Trade": {
        title: "거래방법",
        steps: [
            {
                step: 'STEP 1',
                stepName: '종목 및 분거래',
                content: <img src="/img/img_info_2_01.jpg" alt="종목 및 분거래" />,
                description: <p>S&P INDEX에서 보유중인 종목은 <strong>비트코인, 이더리움, 금, FX</strong> 총 4가지입니다.<br />투자 진행 시 원하시는 종목을 먼저 선택하시고 분 거래<strong>(1분, 3분, 5분)</strong>를 설정합니다.<br />기본적으로 비트코인의 1분거래로 시작합니다.</p>
            },
            {
                step: 'STEP 2',
                stepName: '차트',
                content: <img src="/img/img_info_2_02.jpg" alt="차트" />,
                description: <p><h5>1. 차트</h5><br />해당 종목의 차트를 나타납니다.<br />서버, 클라이언트 통신상태에 따라 차이가 있을 수 있습니다.<br />차트는 완벽한 절대값, 결과값이 아니며 데이터를 손쉽게 보기위한 참고로 활용하십시오.<br /><h5>2. 캔들</h5><br />종목이 거래되고 있는 시가 및 매수/매도 도달을 쉽게 확인할 수 있습니다.<br />좀 더 나은 투자를 위한 지표가 됩니다.</p>
            },
            {
                step: 'STEP 3',
                stepName: '계약 및 남은시간',
                content: <img src="/img/img_info_2_03.jpg" alt="계약 및 남은시간" />,
                description: <p><h5>1. 계약 및 남은시간</h5><br />계약시간 : 현재 계약하고 있는 회차<br />남은시간 : 현재 계약하고 회차의 마감시간 <strong>(10초 전 마감)</strong><br /><h5>2. 매수</h5> &amp; 매도 계약<br />계약으로 매수 &amp; 매도를 선택합니다<br />원하는 만큼 금액 버튼을 클릭합니다.<br />해당 회차에 매수 &amp; 매도 하나만 계약이 가능합니다.</p>
            },
            {
                step: 'STEP 4',
                stepName: '거래결과',
                content: <img src="/img/img_info_2_04.jpg" alt="거래결과" />,
                description: <p><h5>1. 거래결과</h5><br />1분거래 시 1분 마다 거래결과가 표시됩니다.<br />거래 결과는 시가 및 종가에 따른 매수 &amp; 매도 그리고 무효가 표시됩니다.<br />진행]은 현재 계약시간 회차에 대한 상태값입니다.</p>
            },
            {
                step: 'STEP 5',
                stepName: '내 거래내역',
                content: <img src="/img/img_info_2_05.jpg" alt="내 거래내역" />,
                description: <p><h5>1. 내 거래내역</h5><br />거래결과 상태값과 내 거래내역은 최소 1회차 차이가 납니다.<br />즉 현재 차트에 대한 체결이 아닌 다음 회차에 계약을 체결합니다.<br />거래결과의 계약시간과 내 거래내역의 거래시간을 확인합니다.<br />매수 &amp; 매도에 맞는 거래결과값이 나오면 수익이 실현됩니다.</p>
            }
        ],
        selected: ''
    }
};


const renderSteps = (guideType) => {
    return (
        <Row>
            {
                guideSteps[guideType].steps.map(step => {
                    return (
                        <GuideStep step={step.step} stepName={step.stepName} content={step.content} description={step.description} />
                    );
                })
            }
        </Row>
    );
}

const renderCaution = (guideType) => {
    if (guideType === 'Trade') {
        return (
            <div>
                <h5 className='caution-title'>주의사항 !</h5>
                <ul className='caution'>
                    <li>차트는 절대값이 아닌 참고용 차트 입니다</li>
                    <li>갑작스러운 거래량, 거래금액의 증가 또는 데이터 오류로 부정확한 거래소 API값은 제외합니다</li>
                    <li>항상 위험이 상존하며 전산 장애가 있을 수 있음을 안내 드립니다</li>
                    <li>무리한 계약은 자제하시고 신중한 계약을 부탁드립니다</li>
                </ul>
            </div>
        )
    } else {
        return (<div></div>);
    }
}

class Guide extends React.Component {

    render() {
        var type = this.props.match.params.guideType;
        return (
            <div className='container'>
                <Tabs tabData={guideSteps} path={this.props.match.path} wildCard=':guideType' selected={type} />
                {renderSteps(type)}
                {renderCaution(type)}
            </div>
        );
    }
}

export default withRouter(Guide);