import React from 'react';
import {
    // UncontrolledCarousel,
    Row,
    Col,
    Button
} from 'reactstrap';
import { Link } from 'react-router-dom';

import '../assets/scss/Home.scss';
import MainCarousel from '../components/MainCarousel';

const items = [
    {
        src: './img/bg_main_01.jpg',
        altText: 'Slide 1',
        content: <div>
            <h1>걱정하지마세요!</h1>
            <h1>최적의 거래환경을 제공합니다.</h1>
            <p>S&P INDEX는 빠르고 안전한 거래에만 온전히 집중할 수 있도록 해주는<br /><strong>온라인 투자 트레이딩플랫폼입니다.</strong></p>
            <Button className="start-btn" color="info" onClick={() => { window.location.href = '/Exchange/BTCUSDT'; }}>지금 시작하기</Button>
            <div className="features">
                <div>
                    <img src="./img/img_benefit_01.png" alt="check" />
                    <span>
                        쉽고 간편한<br />
                        결과 확인
                    </span>
                </div>
                <div>
                    <img src="./img/img_benefit_02.png" alt="24h" />
                    <span>
                        24시간<br />
                        투자 가능
                </span>
                </div>
                <div>
                    <img src="./img/img_benefit_03.png" alt="trade" />
                    <span>
                        실시간 환율 적용
                </span>
                </div>
                <div>
                    <img src="./img/img_benefit_04.png" alt="secure" />
                    <span>
                        안전한 방화벽<br />
                        DDOS 대응
                    </span>
                </div>
            </div>
        </div>,
        id: '1',
        caption: ''
    },
];


class Home extends React.Component {

    // constructor() {

    // }

    render() {
        return (
            <React.Fragment>
                <div className="home_carousel_spacer"></div>
                <MainCarousel className="home_carousel" items={items} interval={5000} />
                {/* <UncontrolledCarousel className="home_carousel" items={items} /> */}
                <div className="card-area">
                    <div className="card-content">
                        <Row>
                            <Col sm="4">
                                <div className="h-card deep-purple">
                                    <img src="./img/info_img01.jpg" alt="회원가입 안내" />
                                    <div className="card-info">
                                        <h1>회원가입 안내</h1>
                                        <img className="btn-more" src="./img/btn_more_1.png" alt="+" />
                                        <p>S&P INDEX에 가입하시면<br /> <strong>다양한 서비스를<br /> 제공 받을 수 있습니다.</strong></p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="4">
                                <div className="h-card light-purple">
                                    <img src="./img/info_img02.jpg" alt="거래방법" />
                                    <div className="card-info">
                                        <h1>거래방법</h1>
                                        <img className="btn-more" src="./img/btn_more_1.png" alt="+" />
                                        <p>실시간 거래를 기반으로<br /> 매수/매도하여 <br /><strong>수익을 실현시키세요.</strong></p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="4">
                                <div className="h-card deep-purple">
                                    <img src="./img/info_img03.jpg" alt="입출금 신청" />
                                    <div className="card-info">
                                        <h1>입출금 신청</h1>
                                        <img className="btn-more" src="./img/btn_more_1.png" alt="+" />
                                        <p>보안강화를 위해 입금계좌를<br /> 발송해 드리며 <strong><br />언제든 입출금이 가능합니다.</strong></p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="latest-content">
                        <Row>
                            <Col sm="12" lg="6">
                                <div className="box_notice">
                                    <div className="title"><strong>공지사항</strong> <Link to="/Board/Notice"><img src="./img/btn_more_2.png" alt="more" /></Link></div>
                                    <ul>
                                        <li><Link to="/service/noticeView?bid=283"><span>필독!!  (트레이딩 차표와  바이낸스 결과값)</span> <span>2021-03-24</span></Link></li>
                                        <li><Link to="/service/noticeView?bid=66"><span>무효처리 또는 연장회차 기준</span> <span>2021-03-10</span></Link></li>
                                        <li><Link to="/service/noticeView?bid=45"><span>필독!!     입금 출금  방법 및 유의사항</span> <span>2021-03-07</span></Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm="12" lg="6">
                                <div className="box_service">
                                    <div className="title"><strong>고객센터</strong></div>
                                    <ul>
                                        <li><span>출금시간</span><span>00 : 30 ~ 23 : 30</span></li>
                                        <li><span>입금시간</span><span>00 : 30 ~ 23 : 30</span></li>
                                        <li><span>이용시간</span><span>00 : 00 ~ 24 : 00</span></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* <Row className="latest-area">
                    <Col>

                    </Col>
                </Row> */}
            </React.Fragment>
        );
    }
}

export default Home;