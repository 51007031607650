import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap';
import '../assets/scss/Board.scss';

class BoardWrite extends Component {
    state = { boardId: 'Notice' };

    constructor(props) {
        super(props);
        this.inputTitle = React.createRef();
        this.inputContent = React.createRef();
    }

    render() {
        let boardId = this.props.match.params.boardId;
        this.boardId = boardId;

        return (
            <div className='container board-write'>
                <h1>{ (boardId === 'Inquiry' ? '1:1문의' : '게시판') }</h1>
                <input ref={this.inputTitle} name='title' type='text' placeholder='제목' />
                <textarea ref={this.inputContent} name='content'></textarea>
                <div className='button-area'>
                <Button size='md' color='primary' onClick={this.write}>글쓰기</Button>
                <Button size='md' color='danger' onClick={() => { window.history.back(); }}>취소</Button>
                </div>
            </div>
        )
    }

    write = () => {
        const title = this.inputTitle.current.value;
        const content = this.inputContent.current.value;
        
        if (title.length >= 3 && content.length >= 3) {
            var endPoint = (this.boardId === 'Inquiry' ? 'contactus' : 'board');
            fetch('/api/' + endPoint, {
                method: "POST",
                headers: {
                    "Content-Type": 'application/json',
                    "Authorization": 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    title: title,
                    content: content
                })
            })
            .then(res => res.json())
            .then(res => {
                if (res.status === 'OK') {
                    window.location.href = (this.boardId === 'Inquiry' ? '/MyPage/Inquiry' : '/Board/Notice');
                    //do something
                } else {
                    alert('게시글을 작성하는 도중 오류가 발생했습니다.');
                    //do something
                }
            });
        } else {
            alert('제목 또는 내용이 너무 짧습니다.');
        }
    }
}


const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BoardWrite))