import React from 'react';
import Table from '../components/Table';
import '../assets/scss/Exchange.scss';
import iconArrUpDown from '../assets/img/icon_arr_updown.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import socketio from 'socket.io-client';
import CandleChart from '../components/CandleChart';
import Loading from '../components/Loading';

const defaultLotCount = {
    buy: {
        _10000: 0,
        _100000: 0,
        _500000: 0,
        _1000000: 0,
        _5000000: 0,
        _10000000: 0,
    },
    sell: {
        _10000: 0,
        _100000: 0,
        _500000: 0,
        _1000000: 0,
        _5000000: 0,
        _10000000: 0,
    }
};

class Exchange extends React.Component {
    state = {
        gameType: undefined,
        navSelected: {
            BTCUSDT: 'selected',
            ETHUSDT: '',
            XAUUSD: '',
            GBPAUD: '',
            KOSPI200: '',
            NDX: ''
        },
        intervalSelected: {
            m1: 'selected',
            m2: '',
            m3: '',
            m5: ''
        },
        interval: 1,
        currentGame: undefined,
        countDown: '00 : 59',
        lotCount: {
            buy: {
                _10000: 0,
                _100000: 0,
                _500000: 0,
                _1000000: 0,
                _5000000: 0,
                _10000000: 0,
            },
            sell: {
                _10000: 0,
                _100000: 0,
                _500000: 0,
                _1000000: 0,
                _5000000: 0,
                _10000000: 0,
            }
        },
        buyAmount: 0,
        sellAmount: 0,
        myHistory: [],
        ohlc: {
            Close: 0,
            CloseTime: "2021-04-19T14:44:59.999Z",
            High: 0,
            Low: 0,
            Open: 0,
        },
        history: [],
        initialized: false
    };

    typeDiff = {
        BTCUSDT: 1,
        ETHUSDT: 0.1,
        XAUUSD: 0.01,
        GBPAUD: 0.0001,
        KOSPI200: 0.05,
        NDX: 0.5
    }

    symbolFixed = {
        BTCUSDT: 2,
        ETHUSDT: 2,
        XAUUSD: 3,
        GBPAUD: 5,
        KOSPI200: 2,
        NDX: 2
    };


    constructor(props) {
        super(props);
        this.refBuyAmount = React.createRef();
        this.refSellAmount = React.createRef();
    }

    render() {
        /*
        'BTCUSDT'
         */
        //nolog//console.log(this.state.gameType);
        if (this.state.gameType !== undefined) {
            if (this.state.gameType !== 'GBPAUD') { //this.state.gameType === 'BTCUSDT' || this.state.gameType === 'ETHUSDT' //this.state.gameType === 'BTCUSDT'
                return (
                    <div className='container exchange'>
                        <this.navigator />
                        <this.boxChart />
                        <this.boxCandle />
                        <this.boxExchange />
                        <this.boxMyExchangeHistories />
                        <this.boxExchangeHistories />
                        {/* <this.boxExchangeStatistics /> */}
                    </div>
                );
            } else {
                if (!this.isMessageShown) {
                    this.isMessageShown = true;
                    alert('점검중입니다.');
                    window.location.href = '/';
                }
                return (
                    <div>
                    </div>
                )
            }
        }
        else {
            return (
                <div className='container exchange'>
                    <Loading />
                </div>
            );
        }
    }

    componentWillUnmount() {
        if (this.socket) {
            this.socket.close();
        }

        if (this.calcCountDownInterval) {
            clearInterval(this.calcCountDownInterval);
        }

        if (this.getGameInterval) {
            clearInterval(this.getGameInterval);
        }
    }

    componentDidUpdate() {
        let gameType = this.props.match.params.gameType;

        if (gameType !== this.state.gameType) {
            let newNavSelected = { BTCUSDT: '', ETHUSDT: '', XAUUSD: '', GBPAUD: '', KOSPI200: '', NDX: '' };
            newNavSelected[gameType] = 'selected';

            if (this.state.initialized) {
                this.getGameInfo(gameType, this.state.interval);
                this.getMyHistory(gameType, this.state.interval);
                this.getHistory(gameType, this.state.interval);
            }

            this.setState({ gameType: gameType, navSelected: newNavSelected });
        }

        // //nolog//console.log(this.state.gameType);
        if (this.state.gameType !== undefined && !this.state.initialized) {
            this.initializeComponent();
            this.setState({ initialized: true });
        }


        // this.getGameInfo();
    }

    componentDidMount() {
        let gameType = this.props.match.params.gameType;

        if (gameType !== this.state.gameType) {
            let newNavSelected = { BTCUSDT: '', ETHUSDT: '', XAUUSD: '', GBPAUD: '', KOSPI200: '', NDX: '' };
            newNavSelected[gameType] = 'selected';

            if (this.state.initialized) {
                this.getGameInfo(gameType, this.state.interval);
                this.getMyHistory(gameType, this.state.interval);
                this.getHistory(gameType, this.state.interval);
            }

            this.setState({ gameType: gameType, navSelected: newNavSelected });
        }

        // //nolog//console.log(this.state.gameType);
        if (this.state.gameType !== undefined && !this.state.initialized) {
            this.initializeComponent();
            this.setState({ initialized: true });
        }
    }

    initializeComponent() {
        if (localStorage.token === undefined || localStorage.token === null || localStorage.token === '') {
            window.location.href = '/Login';
        }

        let gameType = this.props.match.params.gameType;

        if (gameType !== this.state.gameType) {
            let newNavSelected = { BTCUSDT: '', ETHUSDT: '', XAUUSD: '', GBPAUD: '', KOSPI200: '', NDX: '' };
            newNavSelected[gameType] = 'selected';
            this.setState({ gameType: gameType, navSelected: newNavSelected });
        }

        if (this.props.user) {
            if (this.calcCountDownInterval) {
                clearInterval(this.calcCountDownInterval);
            }

            if (this.getGameInterval) {
                clearInterval(this.getGameInterval);
            }

            this.getGameInterval = setInterval(this.getGameInfo, 3000);
            this.calcCountDownInterval = setInterval(() => {
                let info = this.state.currentGame;
                if (info !== undefined) {
                    let t1 = Date.parse(info.date);
                    let t2 = Date.now() + (9 * 60 * 60 * 1000); // UTC +9만큼 계산
                    let diff = Math.floor((t1 - t2) / 1000) - 10;

                    if (diff < 0) {
                        return;
                    }
                    // //nolog//console.log(diff);
                    let m = Math.floor(diff / 60);
                    let s = diff % 60;
                    this.setState({ countDown: String(m).padStart(2, '0') + ' : ' + String(s).padStart(2, '0') });
                }
            }, 100);
        }
        this.getGameInfo();
        this.getMyHistory();
        this.getHistory();

        this.socket = socketio.connect('/');

        const socket = this.socket;

        socket.on('connect', function (arg) {
            //nolog//console.log('server connected');
            // socket.emit('Get24H', { symbol: 'BTCUSDT'});
        });

        socket.on('TickUpdated', (res) => {
            if (res.symbol !== this.state.gameType)
                return;
            if (res.interval !== this.state.interval)
                return;
            // //nolog//console.log(this.state.interval);

            this.setState({ ohlc: res.data[0] });
            // //nolog//console.log(res);
        });
    }

    boxChart = () => {
        return (
            <div className='box-chart'>
                <div>
                    <CandleChart key={this.state.gameType + '_' + this.state.interval} symbol={this.state.gameType} interval={this.state.interval} />
                </div>
            </div>
        );
    }

    /*
 
    [
        <span className='con'>
            <span className='round'>910회</span>
            <span className='time'>08일 15시 10분</span>
        </span>,
        '1분',
        <span className='label-sell'>Short</span>,
        '50872.95',
        <span>100,000</span>,
        <span className='ex-btn ex-btn-success'>실현</span>
    ],
    [
        <span className='con'>
            <span className='round'>911회</span>
            <span className='time'>08일 15시 10분</span>
        </span>,
        '1분',
        <span className='label-buy'>Long</span>,
        '50872.95',
        <span>100,000</span>,
        <span className='ex-btn ex-btn-fail'>실격</span>
    ]


*/

    getMyHistory = (aGameType, aInterval) => {
        const gameType = aGameType !== undefined ? aGameType : this.state.gameType;
        const interval = aInterval !== undefined ? aInterval : this.state.interval;
        //nolog//console.log('myHist', gameType, interval);
        fetch('/api/exchange/myHistory?gameType=' + gameType + '&interval=' + interval, {
            method: "GET",
            headers: {
                "Authorization": 'Bearer ' + localStorage.token
            }
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === 'OK') {
                    //do something
                    let newMyHistory = [];

                    res.data.forEach(history => {
                        let statusText = '신청';
                        let statusClass = 'ex-btn-pending';
                        if (history.gameStatus === 3) {
                            if (history.isSuccess === 0) {
                                statusText = '실격';
                                statusClass = 'ex-btn-fail';
                            } else {
                                statusText = '실현';
                                statusClass = 'ex-btn-success';
                            }
                        }
                        // //nolog//console.log(history);
                        let openPrice = (history.score && history.score.includes(':')) ? history.score.split(':')[1] : 'NA';
                        if (openPrice === '0') {
                            openPrice = 'NA';
                        }
                        newMyHistory.push([
                            <span className='con'>
                                <span className='round'>{history.round}회</span>
                                <span className='time'>{moment(history.betDate).utc().format('DD일 HH시 mm분')}</span>
                            </span>,
                            history.interval + '분',
                            <span className={'label-' + (history.selectedTeam === 1 ? 'buy' : 'sell')}>{history.selectedTeam === 1 ? '매수' : '매도'}</span>,
                            openPrice,
                            <span>{parseInt(history.amount).toLocaleString()}</span>,
                            <span className={'ex-btn ' + statusClass}>{statusText}</span>
                        ]);
                    });
                    // //nolog//console.log(newMyHistory);

                    this.setState({ myHistory: newMyHistory });
                    // //nolog//console.log(newMyHistory);
                } else {
                    this.setState({ myHistory: [] });
                    //do something
                }
            });


    }

    getHistory = (aGameType, aInterval) => {
        const gameType = aGameType !== undefined ? aGameType : this.state.gameType;
        const interval = aInterval !== undefined ? aInterval : this.state.interval;
        fetch('/api/exchange/history?gameType=' + gameType + '&interval=' + interval, {
            method: "GET",
            headers: {
                "Authorization": 'Bearer ' + localStorage.token
            }
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === 'OK') {
                    //do something

                    this.setState({ history: res.data });
                } else {
                    this.setState({ history: [] });
                    //do something
                }
            });


    }


    getGameInfo = (aGameType, aInterval) => {
        const gameType = aGameType !== undefined ? aGameType : this.state.gameType;
        const interval = aInterval !== undefined ? aInterval : this.state.interval;
        fetch('/api/exchange?gameType=' + gameType + '&interval=' + interval, {
            method: "GET",
            headers: {
                "Authorization": 'Bearer ' + localStorage.token
            }
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === 'OK') {
                    // //nolog//console.log(res);

                    if (res.data.gameCode === undefined) {
                        this.setState({ currentGame: undefined, lotCount: { ...defaultLotCount }, sellAmount: 0, buyAmount: 0 });
                    } else if (res.data !== this.state.currentGame) {
                        this.setState({ currentGame: res.data });
                    }
                    //do something
                } else {
                    //do something
                }
            });
    };

    navButtonHandler = (event) => {
        let gameType = event.target.getAttribute('game-type');
        let newNavSelected = { BTCUSDT: '', ETHUSDT: '', XAUUSD: '', GBPAUD: '', KOSPI200: '', NDX: '' };
        newNavSelected[gameType] = 'selected';
        this.setState({ navSelected: newNavSelected });
        window.location.href = '/Exchange/' + gameType;
    }

    intervalButtonHandler = (event) => {
        let interval = parseInt(event.target.getAttribute('interval'));
        let newIntervalSelected = { m1: '', m2: '', m3: '', m5: '' };
        newIntervalSelected['m' + interval] = 'selected';
        this.setState({ intervalSelected: newIntervalSelected, interval: interval });
        this.getGameInfo(this.state.gameType, interval);
        this.getMyHistory(this.state.gameType, interval);
        this.getHistory(this.state.gameType, interval);
    }

    increaseLotHandler = (event) => {
        let kind = event.target.getAttribute('kind');
        let lot = parseInt(event.target.getAttribute('lot'));
        let lotCount = this.state.lotCount;
        lotCount[kind]['_' + lot]++;



        this.setState({ lotCount: lotCount });

        this.calcLotAmount(lotCount);
    }

    resetLotCountHandler = (event) => {
        let kind = event.target.getAttribute('kind');
        const lots = [10000, 100000, 500000, 1000000, 5000000, 10000000];
        let lotCount = { ...this.state.lotCount }
        lots.forEach(iLot => {
            lotCount[kind]['_' + iLot] = 0;
        });

        this.setState({ lotCount: lotCount });

        this.calcLotAmount(lotCount);
    }

    calcLotAmount = (lotCount) => {
        let buyAmount = 0;
        let sellAmount = 0;

        const lots = [10000, 100000, 500000, 1000000, 5000000, 10000000];

        lots.forEach(iLot => {
            buyAmount += iLot * lotCount['buy']['_' + iLot];
            sellAmount += iLot * lotCount['sell']['_' + iLot];
        });

        this.setState({ buyAmount: buyAmount, sellAmount: sellAmount });
    }

    requestBuy = (event) => {
        if (this.state.buyAmount < 5000) {
            alert('매수 금액이 최소 금액보다 적습니다.');
            return;
        }

        if (!this.state.currentGame) {
            alert('잠시 후 다시시도해주세요.');
            return;
        }

        fetch('/api/exchange', {
            method: "POST",
            headers: {
                "Content-Type": 'application/json',
                "Authorization": 'Bearer ' + localStorage.token
            },
            body: JSON.stringify({
                rate: parseFloat(this.state.currentGame.homeRate),
                amount: this.state.buyAmount,
                typeCode: this.state.currentGame.typeCode,
                gameCode: this.state.currentGame.gameCode,
                selectedTeam: 1
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === 'OK') {
                    alert('매수가 완료되었습니다.');
                    window.location.reload();
                    //do something
                } else {
                    alert('매수에 실패했습니다.');
                    //do something
                }
            })
            .catch(err => {
                alert('매수에 실패했습니다.');
            });
    };

    requestSell = (event) => {
        if (this.state.sellAmount < 5000) {
            alert('매도 금액이 최소 금액보다 적습니다.');
            return;
        }

        if (!this.state.currentGame) {
            alert('잠시 후 다시시도해주세요.');
            return;
        }

        fetch('/api/exchange', {
            method: "POST",
            headers: {
                "Content-Type": 'application/json',
                "Authorization": 'Bearer ' + localStorage.token
            },
            body: JSON.stringify({
                rate: parseFloat(this.state.currentGame.awayRate),
                amount: this.state.sellAmount,
                typeCode: this.state.currentGame.typeCode,
                gameCode: this.state.currentGame.gameCode,
                selectedTeam: 3
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === 'OK') {
                    alert('매도가 완료되었습니다.');
                    window.location.reload();
                    //do something
                } else {
                    alert('매도에 실패했습니다.');
                    //do something
                }
            })
            .catch(err => {
                alert('매도에 실패했습니다.');
            });
    };

    navigator = () => {
        return (
            <nav>
                <div className='game-types'>
                    <span className={this.state.navSelected.NDX} game-type='NDX' onClick={this.navButtonHandler}>
                        나스닥
                    </span>
                    <span className={this.state.navSelected.KOSPI200} game-type='KOSPI200' onClick={this.navButtonHandler}>
                        코스피
                    </span>
                    <span className={this.state.navSelected.BTCUSDT} game-type='BTCUSDT' onClick={this.navButtonHandler}>
                        비트코인
                    </span>
                    <span className={this.state.navSelected.ETHUSDT} game-type='ETHUSDT' onClick={this.navButtonHandler}>
                        이더리움
                    </span>
                    <span className={this.state.navSelected.XAUUSD} game-type='XAUUSD' onClick={this.navButtonHandler}>
                        골드
                    </span>
                    {/* <span className={this.state.navSelected.GBPAUD} game-type='GBPAUD' onClick={this.navButtonHandler}>
                        FX
                    </span> */}
                </div>
                <div className='intervals'>
                    <span className={this.state.intervalSelected.m1} interval='1' onClick={this.intervalButtonHandler}>
                        1분거래
                    </span>
                    {/* <span className={this.state.intervalSelected.m2} interval='2' onClick={this.intervalButtonHandler}>
                        2분거래
                    </span> */}
                    <span className={this.state.intervalSelected.m3} interval='3' onClick={this.intervalButtonHandler}>
                        3분거래
                    </span>
                    <span className={this.state.intervalSelected.m5} interval='5' onClick={this.intervalButtonHandler}>
                        5분거래
                    </span>
                </div>
            </nav>
        );
    }


    boxCandle = () => {
        return (
            <div className='box-candle'>
                <div>
                    <div className='box-bar'>

                        <div className='vertical-line'>
                            <div className='min'>
                                <div className='fill'></div>
                            </div>
                            <div className='max'>
                                <div className='fill'></div>
                            </div>
                        </div>
                        <div className='high candle-cursor'>
                            <div>
                                <span className='cursor-name'>매수</span>
                                <span className='cursor-value'>{(this.state.ohlc.Open + (this.typeDiff[this.state.gameType] * this.state.interval)).toFixed(this.symbolFixed[this.state.gameType])}</span>
                            </div>
                        </div>
                        <div className='low candle-cursor'>
                            <div>
                                <span className='cursor-name'>매도</span>
                                <span className='cursor-value'>{(this.state.ohlc.Open - (this.typeDiff[this.state.gameType] * this.state.interval)).toFixed(this.symbolFixed[this.state.gameType])}</span>
                            </div>
                        </div>
                        <div className={'current candle-cursor ' + ((this.state.ohlc.Open - this.state.ohlc.Close) < 0 ? 'red' : 'blue')} style={{
                            top: `calc(${Math.min(Math.max(50 + ((this.state.ohlc.Open - this.state.ohlc.Close) / (this.typeDiff[this.state.gameType] * this.state.interval)) * 35.0, 0), 100)}% - 16px)`
                        }}>
                            <div>
                                <span className='cursor-name'>현재</span>
                                <span className='cursor-value'>{this.state.ohlc.Close.toFixed(this.symbolFixed[this.state.gameType])}</span>
                            </div>
                        </div>
                        <div className='close-_ candle-cursor'>
                            <div>
                                <span className='cursor-name'>시작</span>
                                <span className='cursor-value'>{this.state.ohlc.Open.toFixed(this.symbolFixed[this.state.gameType])}</span>
                            </div>
                        </div>
                        <div className='vertical-bar'>
                            <div className='min' style={{
                                height: `${Math.min(Math.max(((this.state.ohlc.Open - this.state.ohlc.Close) / (this.typeDiff[this.state.gameType] * this.state.interval)) * 35.0, 0), 50)}%`
                            }}>
                                <div className='fill'></div>
                            </div>
                            <div className='max' style={{
                                height: `${Math.min(Math.max((-(this.state.ohlc.Open - this.state.ohlc.Close) / (this.typeDiff[this.state.gameType] * this.state.interval)) * 35.0, 0), 50)}%`
                            }}>
                                <div className='fill'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    boxExchange = () => {
        const thead = [
            { text: '수량', width: '11%' },
            { text: '실현/실격', width: '19%' },
            { text: '매수', width: '35%' },
            { text: '매도', width: '35%' }
        ];
        let tbody = [
            [
                <span className='lot'>1 Lot</span>,
                <span className='ext'>{this.state.interval} USDT <img src={iconArrUpDown} alt='arrow' /></span>,
                <span className='ex'>
                    <span className='ex-btn btn-buy' kind='buy' lot='10000' onClick={this.increaseLotHandler}>10,000 ({this.state.lotCount.buy._10000})</span>
                    <span className='ex-btn btn-buy-max'>MAX</span>
                </span>,
                <span className='ex'>
                    <span className='ex-btn btn-sell' kind='sell' lot='10000' onClick={this.increaseLotHandler}>10,000 ({this.state.lotCount.sell._10000})</span>
                    <span className='ex-btn btn-sell-max'>MAX</span>
                </span>
            ],
            [
                <span className='lot'>10 Lot</span>,
                <span className='ext'>{this.state.interval} USDT <img src={iconArrUpDown} alt='arrow' /></span>,
                <span className='ex'>
                    <span className='ex-btn btn-buy' kind='buy' lot='100000' onClick={this.increaseLotHandler}>100,000 ({this.state.lotCount.buy._100000})</span>
                    <span className='ex-btn btn-buy-max'>MAX</span>
                </span>,
                <span className='ex'>
                    <span className='ex-btn btn-sell' kind='sell' lot='100000' onClick={this.increaseLotHandler}>100,000 ({this.state.lotCount.sell._100000})</span>
                    <span className='ex-btn btn-sell-max'>MAX</span>
                </span>
            ],
            [
                <span className='lot'>50 Lot</span>,
                <span className='ext'>{this.state.interval} USDT <img src={iconArrUpDown} alt='arrow' /></span>,
                <span className='ex'>
                    <span className='ex-btn btn-buy' kind='buy' lot='500000' onClick={this.increaseLotHandler}>500,000 ({this.state.lotCount.buy._500000})</span>
                    <span className='ex-btn btn-buy-max'>MAX</span>
                </span>,
                <span className='ex'>
                    <span className='ex-btn btn-sell' kind='sell' lot='500000' onClick={this.increaseLotHandler}>500,000 ({this.state.lotCount.sell._500000})</span>
                    <span className='ex-btn btn-sell-max'>MAX</span>
                </span>
            ],
            [
                <span className='lot'>100 Lot</span>,
                <span className='ext'>{this.state.interval} USDT <img src={iconArrUpDown} alt='arrow' /></span>,
                <span className='ex'>
                    <span className='ex-btn btn-buy ex-btn-fontSize10' kind='buy' lot='1000000' onClick={this.increaseLotHandler}>1,000,000 ({this.state.lotCount.buy._1000000})</span>
                    <span className='ex-btn btn-buy-max'>MAX</span>
                </span>,
                <span className='ex'>
                    <span className='ex-btn btn-sell ex-btn-fontSize10' kind='sell' lot='1000000' onClick={this.increaseLotHandler}>1,000,000 ({this.state.lotCount.sell._1000000})</span>
                    <span className='ex-btn btn-sell-max'>MAX</span>
                </span>
            ],
            [
                <span className='lot'>500 Lot</span>,
                <span className='ext'>{this.state.interval} USDT <img src={iconArrUpDown} alt='arrow' /></span>,
                <span className='ex'>
                    <span className='ex-btn btn-buy ex-btn-fontSize10' kind='buy' lot='5000000' onClick={this.increaseLotHandler}>5,000,000 ({this.state.lotCount.buy._5000000})</span>
                    <span className='ex-btn btn-buy-max'>MAX</span>
                </span>,
                <span className='ex'>
                    <span className='ex-btn btn-sell ex-btn-fontSize10' kind='sell' lot='5000000' onClick={this.increaseLotHandler}>5,000,000 ({this.state.lotCount.sell._5000000})</span>
                    <span className='ex-btn btn-sell-max'>MAX</span>
                </span>
            ],
            [
                <span className='lot'>1000 Lot</span>,
                <span className='ext'>{this.state.interval} USDT <img src={iconArrUpDown} alt='arrow' /></span>,
                <span className='ex'>
                    <span className='ex-btn btn-buy ex-btn-fontSize10' kind='buy' lot='10000000' onClick={this.increaseLotHandler}>10,000,000 ({this.state.lotCount.buy._10000000})</span>
                    <span className='ex-btn btn-buy-max'>MAX</span>
                </span>,
                <span className='ex'>
                    <span className='ex-btn btn-sell ex-btn-fontSize10' kind='sell' lot='10000000' onClick={this.increaseLotHandler}>10,000,000 ({this.state.lotCount.sell._10000000})</span>
                    <span className='ex-btn btn-sell-max'>MAX</span>
                </span>
            ],
            [
                undefined,
                <span className='label-amount'>신청금액</span>,
                <span className='amt'>
                    <input className='buy-amount' type='number' value={this.state.buyAmount} ref={this.refBuyAmount} onChange={() => {
                        let amount = parseInt(this.refBuyAmount.current.value);
                        this.setState({ buyAmount: amount });
                    }} style={{
                        textAlign: 'right',
                        background: 'transparent',
                        border: 0,
                        width: 'calc(100% - 60px)'
                    }} />
                    <button className='btn-cancel' kind='buy' onClick={this.resetLotCountHandler}>취소</button>
                </span>,
                <span className='amt'>
                    <input className='sell-amount' type='number' value={this.state.sellAmount} ref={this.refSellAmount} onChange={() => {
                        let amount = parseInt(this.refSellAmount.current.value);
                        this.setState({ sellAmount: amount });
                    }} style={{
                        textAlign: 'right',
                        background: 'transparent',
                        border: 0,
                        width: 'calc(100% - 60px)'
                    }} />
                    <button className='btn-cancel' kind='sell' onClick={this.resetLotCountHandler}>취소</button>
                </span>
            ],
            [
                undefined,
                <span>&nbsp;</span>,
                <span>
                    <span className='ex-btn btn-buy-ok' onClick={this.requestBuy}>매수</span>
                    <span className='ex-btn btn-buy-max-ok' onClick={() => {
                        this.setState({ buyAmount: this.props.user.cash });
                    }}>MAX</span>
                </span>,
                <span>
                    <span className='ex-btn btn-sell-ok' onClick={this.requestSell}>매도</span>
                    <span className='ex-btn btn-sell-max-ok' onClick={() => {
                        this.setState({ sellAmount: this.props.user.cash });
                    }}>MAX</span>
                </span>
            ]
        ];
        return (
            <div className='box-exchange'>
                <div>
                    <div className='label-heading'>
                        <div className='remaining-time-area'>
                            <span className='name'>남은시간</span>
                            <div className='value'>
                                <span className='info'>10초 마감</span>
                                <span className='time'>{this.state.countDown}</span>
                            </div>
                        </div>
                        <div className='contract-time-area'>
                            <span className='name'>계약시간</span>
                            <div className='value'>
                                <span className='info'>{this.state.currentGame ? this.state.currentGame.round + '회' : 'loading'}</span>
                                <span className='time'>{this.state.currentGame ? moment(this.state.currentGame.date).utc().format('HH시 mm분') : 'loading'}</span>
                            </div>
                        </div>
                        <Table thead={thead} tbody={tbody} />
                    </div>
                </div>
            </div>
        );
    }

    boxMyExchangeHistories = () => {
        const thead = [
            { text: '계약시간', width: '27%' },
            { text: '시간', width: '10%' },
            { text: '구분', width: '10%' },
            { text: '시가', width: '17%' },
            { text: '금액', width: '19%' },
            { text: '결과', width: '17%' }
        ];

        return (
            <div className='box-my-exchange-histories'>
                <div>
                    <div className='label-heading'>내 거래내역</div>
                    <Table key={this.state.gameType + '_' + this.state.interval} thead={thead} tbody={this.state.myHistory} empty='&nbsp;' />
                </div>
            </div>
        );
    }

    boxExchangeHistories = () => {
        const thead = [
            { text: '계약시간', width: '36%' },
            { text: '시가', width: '22%' },
            { text: '체결가', width: '22%' },
            { text: '결과', width: '20%' }
        ];

        let tbody = [];

        this.state.history.forEach(history => {
            const sScore = history.score.split(':');
            tbody.push([
                <span className='con'>
                    <span className='round'>{history.round}회</span>
                    <span className='time'>{moment(history.date).utc().format('DD일 HH시 mm분')}</span>
                </span>,
                sScore[1],
                sScore[0],
                <span className={'ex-btn ex-btn-' + (history.winTeam === 1 ? 'buy' : 'sell')}>{history.winTeam === 1 ? '매수' : '매도'}</span>
            ]);
        });

        // let tbody = [
        //     [
        //         <span className='con'>
        //             <span className='round'>910회</span>
        //             <span className='time'>08일 15시 10분</span>
        //         </span>,
        //         '58671.20',
        //         '58672.44',
        //         <span className='ex-btn ex-btn-sell'>Short</span>
        //     ],
        //     [
        //         <span className='con'>
        //             <span className='round'>911회</span>
        //             <span className='time'>08일 15시 10분</span>
        //         </span>,
        //         '58671.20',
        //         '58672.44',
        //         <span className='ex-btn ex-btn-buy'>Long</span>
        //     ]
        // ];

        return (
            <div className='box-exchange-histories'>
                <div>
                    <div className='label-heading'>거래결과</div>
                    <Table key={this.state.gameType + '_' + this.state.interval} thead={thead} tbody={tbody} empty='&nbsp;' />
                </div>
            </div>
        );
    }

    boxExchangeStatistics = () => {
        let buyCount = 0;
        let sellCount = 0;
        let groups = [];

        let prevResult = '';
        let n = 1;
        let rh = [...this.state.history];
        rh = rh.reverse();
        rh.forEach(history => {
            const result = (history.winTeam === 1 ? 'buy' : 'sell');
            if (result === 'buy') {
                buyCount++;
            } else {
                sellCount++;
            }

            if (prevResult !== result) {
                prevResult = result;
                groups.push({
                    result: result,
                    items: []
                });
            }

            groups[groups.length - 1].items.push({ round: history.round, code: history.gameCode });

            // const sScore = history.score.split(':');
            // tbody.push([
            //     <span className='con'>
            //         <span className='round'>{history.round}회</span>
            //         <span className='time'>{moment(history.date).utc().format('DD일 HH시 mm분')}</span>
            //     </span>,
            //     sScore[1],
            //     sScore[0],
            //     <span className={'ex-btn ex-btn-' + (history.winTeam === 1 ? 'buy' : 'sell')}>{history.winTeam === 1 ? 'Long' : 'Short'}</span>                
            // ]);
        });

        if (this.state.history.length === 0) {
            return (
                <Loading />
            );
        }

        return (
            <div className='box-exchange-statistics'>
                <div>
                    <div className='box-statistics'>
                        <span className='c-buy'>매수</span>
                        <span>
                            <span className='cnt'>{buyCount}번</span>
                            <span className='percent'>({(buyCount / this.state.history.length).toFixed(2)}%)</span>
                        </span>
                        <span className='c-sell'>매도</span>
                        <span>
                            <span className='cnt'>{sellCount}번</span>
                            <span className='percent'>({(sellCount / this.state.history.length).toFixed(2)}%)</span>
                        </span>

                    </div>
                    <div className='box-results'>
                        {
                            groups.map(group => {

                                return (
                                    <div key={this.state.gameType + '_' + this.state.interval + '_' + n}>
                                        <div className={'s-' + group.result}>{(group.result === 'buy' ? '매수' : '매도')}</div>
                                        <div>
                                            {
                                                group.items.map(item => {
                                                    return (
                                                        <span key={item.code} className={'c-' + group.result}>{item.round}</span>
                                                    );
                                                })
                                            }
                                        </div>
                                        <div className={'s-' + group.result}>
                                            {group.items.length}
                                        </div>
                                        <div>
                                            {n++}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Exchange))