import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
//   CarouselControl,
//   CarouselIndicators,
  // CarouselCaption
} from 'react-bootstrap'; //reactstrap

// import {

// } from 'react-bootstrap';

const MainCarousel = (props) => {
  const [activeIndex, ] = useState(0);//setActiveIndex
  // const [animating] = useState(false); //setAnimating

  // const next = () => {
  //   if (animating) return;
  //   const nextIndex = activeIndex === props.items.length - 1 ? 0 : activeIndex + 1;
  //   setActiveIndex(nextIndex);
  // }

  // const previous = () => {
  //   if (animating) return;
  //   const nextIndex = activeIndex === 0 ? props.items.length - 1 : activeIndex - 1;
  //   setActiveIndex(nextIndex);
  // }

//   const goToIndex = (newIndex) => {
//     if (animating) return;
//     setActiveIndex(newIndex);
//   }

  // setTimeout(() => {
  //     next();
  // }, props.interval);

  const slides = props.items.map((item) => {
    return (
      <CarouselItem
        className="carousel-item"
        tag="div"
        key={item.id}
        // onExiting={() => setAnimating(true)}
        // onExited={() => setAnimating(false)}
      >
          <img src={item.src} alt={item.altText} />
          <div className='carousel-item-content'>{item.content}</div>
        {/* <CarouselCaption className="text-danger" captionText={item.caption} captionHeader={item.caption} /> */}
      </CarouselItem>
    );
  });

  return (
      <Carousel
        activeIndex={activeIndex}
        // next={next}
        // previous={previous}
        className={props.className}
        pause={false}
        interval={props.interval}
      >
        {/* <CarouselIndicators items={props.items} activeIndex={activeIndex} onClickHandler={goToIndex} /> */}
        {slides}
        {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} /> */}
        {/* <CarouselControl direction="next" directionText="Next" onClickHandler={next} /> */}
      </Carousel>
  );
}

export default MainCarousel;