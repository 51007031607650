import React from 'react';
import '../assets/scss/Footer.scss';

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <p><img src={this.props.logo} alt={this.props.appName} /></p>
                <p>Copyright © <a href='/'>{this.props.appName}</a> All Rights Reserved.</p>
            </footer>
        );
    }
}

export default Footer;