import React from 'react';
import { withRouter } from 'react-router';
import Tabs from '../components/Tabs';
import '../assets/scss/MyPage.scss';
import { Col, Row } from 'react-bootstrap';
import Table from '../components/Table';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

import moment from 'moment-timezone';

import iconPwOn from '../assets/img/icon_pw_on.png';
import iconPwOff from '../assets/img/icon_pw_off.png';

import { Link } from 'react-router-dom';
import Loading from '../components/Loading';

const gameTypes = [
    'BTCUSDT', 'ETHUSDT', 'XAUUSD', 'GBPAUD', 'KOSPI200', 'NDX'
];
const intervals = [
    1, 3, 5
]

class MyPage extends React.Component {
    state = { 
        newNick: "", 
        newPasswordVerifiedIcon: iconPwOff, 
        messages: [], 
        inquiries: [], 
        bettingHistories: {
            BTCUSDT: {
                _1: [],
                _3: [],
                _5: []
            }, 
            ETHUSDT: {
                _1: [],
                _3: [],
                _5: []
            }, 
            XAUUSD: {
                _1: [],
                _3: [],
                _5: []
            },
            GBPAUD: {
                _1: [],
                _3: [],
                _5: []
            },
            KOSPI200: {
                _1: [],
                _3: [],
                _5: []
            },
            NDX: {
                _1: [],
                _3: [],
                _5: []
            }
        },
        exchangeHistoryTypeSelected: '전체',
        exchangeHistoryRangeSelected: '전체',
     }

    constructor(props) {
        super(props);

        this.passwordInput = React.createRef();
        this.newPasswordInput = React.createRef();
        this.newPasswordRepeatInput = React.createRef();
    }

    componentDidMount() {
        if (localStorage.token === undefined || localStorage.token === null || localStorage.token === '') {
            window.location.href = '/Login';
        }
        
        if (this.props.user) {
            this.getMessages();
            this.getInquiries();
            this.setState({ newNick: this.props.user.nick });


            gameTypes.forEach(gameType => {
                intervals.forEach(interval => {
                    this.getMyHistory(gameType, interval);
                });
            });
        }
    }

    render() {
        let type = this.props.match.params.mypageType;
        return (
            <div className='container myPage'>
                <Tabs tabData={this.tabs} path={this.props.match.path} wildCard=':mypageType' selected={type} />
                { this.renderMyPage(type) }
            </div>
        );
    }

    getMessages = () => {
        if (this.props.user) {
            let messages = [];
            //idx, ti as title, rd as date, ct as viewCount, wnn as sender
            fetch('/api/message', {
                method: "GET",
                headers: {
                    "Authorization": 'Bearer ' + localStorage.token
                }
            })
            .then(res => res.json())
            .then(res => {
                if (res.status === 'OK') {
                    res.data.forEach(message => {
                        messages.push([ message.idx, 
                            <div className="message-title"><span style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                fetch('/api/message/' + message.idx, {
                                    method: "GET",
                                    headers: {
                                        "Authorization": 'Bearer ' + localStorage.token
                                    }
                                })
                                .then(res => res.json())
                                .then(res => {
                                    if (res.status === 'OK') {
                                        document.querySelectorAll(".message-body").forEach(div => {
                                            div.style.padding = "";
                                            div.innerHTML = "";
                                        });

                                        const msgBody = document.getElementById("msg-" + message.idx);

                                        msgBody.innerHTML = res.data.content;
                                        msgBody.style.padding = "30px 15px";
                                        //do something
                                    } else {
                                        //do something
                                    }
                                });
                            }}>{message.title}</span>
                            <div className="message-body" style={{
                            }} id={"msg-" + message.idx}></div>
                            </div>,
                             message.viewCount === 0 ? moment(message.date).utc().format('MM-DD HH:mm') : '읽음' ]);
                    });
                    this.setState({ messages: messages });
                    //do something
                } else {
                    //do something
                }
            });
        }
    }

    getInquiries = () => {
        if (this.props.user) {
            let inquiries = [];
            //idx, ti as title, rd as date, ct as viewCount, wnn as sender
            fetch('/api/contactus?page=1&pageSize=20', {
                method: "GET",
                headers: {
                    "Authorization": 'Bearer ' + localStorage.token
                }
            })
            .then(res => res.json())
            .then(res => {
                if (res.status === 'OK') {
                    res.data.forEach(inquiry => {
                        inquiries.push([ inquiry.idx, <Link to={'/Board/Inquiry/' + inquiry.idx}>{inquiry.title}</Link>, inquiry.nick, moment(inquiry.date).utc().format('MM-DD HH:mm') ]);
                    });
                    this.setState({ inquiries: inquiries });
                    //do something
                } else {
                    //do something
                }
            });
        }    
    }

    getMyHistory = (aGameType, aInterval) => {
        const gameType = aGameType;
        const interval = aInterval;
        fetch('/api/exchange/myHistory?gameType=' + gameType + '&interval=' + interval, {
            method: "GET",
            headers: {
                "Authorization": 'Bearer ' + localStorage.token
            }
        })
        .then(res => res.json())
        .then(res => {
            if (res.status === 'OK') {
                let history = { ...this.state.bettingHistories }
                history[gameType]['_' + interval] = res.data;
                this.setState({ bettingHistories: history });
            } else {
                // this.setState({ bettingHistories: history });
                //do something
            }
        });   
    }

    tabs = {
        "Info": {
            title: "회원정보"
        },
        "ExchangeHistory": {
            title: "나의거래내역"
        },
        "Inquiry": {
            title: "1:1문의"
        },
        "Message": {
            title: "쪽지함"
        },
        // "LoginHistory": {
        //     title: "로그인내역"
        // }
    };

    nickInputChangeHandler = (event) => {
        this.setState({ newNick: event.target.value });
    }

    passwordInputChangeHandler = (event) => {
        const newPassword = this.newPasswordInput.current.value;
        const newPasswordRepeat = this.newPasswordRepeatInput.current.value;
        if (newPassword.length >= 4 && newPassword === newPasswordRepeat) {
            this.setState({ newPasswordVerifiedIcon: iconPwOn });
        } else {
            this.setState({ newPasswordVerifiedIcon: iconPwOff });
        }
    }

    requestPasswordChange = (event) => {
        alert('비밀번호 변경은 고객센터로 문의 바랍니다.');
        // const password = this.passwordInput.current.value;
        // const newPassword = this.newPasswordInput.current.value;
        // const newPasswordRepeat = this.newPasswordRepeatInput.current.value;

        // if (newPassword !== newPasswordRepeat) {
        //     event.target.disabled = '';
        //     alert('비밀번호 확인이 일치하지 않습니다.');
        //     return;
        // }

        // event.target.disabled = 'disabled';
        // fetch('/api/user/password', {
        //     method: "PATCH",
        //     headers: {
        //         "Content-Type": 'application/json',
        //         "Authorization": 'Bearer ' + localStorage.token
        //     },
        //     body: JSON.stringify({
        //         password: password,
        //         newPassword: newPassword,
        //         newPasswordRepeat: newPasswordRepeat
        //     })
        // })
        // .then(res => res.json())
        // .then(res => {
        //     if (res.status === 'OK') {
        //         alert('비밀번호가 변경되었습니다.');
        //         this.passwordInput.current.value = "";
        //         this.newPasswordInput.current.value = "";
        //         this.newPasswordRepeatInput.current.value = "";
        //         event.target.disabled = '';
        //         //do something
        //     } else {
        //         alert('비밀번호 변경에 실패했습니다.');
        //         event.target.disabled = '';
        //         //do something
        //     }
        // })
        // .catch(err => {
        //     alert('비밀번호 변경 도중에 오류가 발생했습니다.');
        //     event.target.disabled = '';
        // });
    }

    requestDepositAccount = () => {
        fetch('/api/contactus', {
            method: "POST",
            headers: {
                "Content-Type": 'application/json',
                "Authorization": 'Bearer ' + localStorage.token
            },
            body: JSON.stringify({
                title: '계좌요청합니다.',
                content: '계좌요청합니다.'
            })
        })
        .then(res => res.json())
        .then(res => {
            if (res.status === 'OK') {
                this.getInquiries();
                //do something
            } else {
                alert('계좌요청에 실패했습니다.');
                //do something
            }
        })
        .catch(err => {
            alert('계좌요청 중에 오류가 발생했습니다.');
        });
    }
    
    Info = () => {
        let user = this.props.user;
        if (user) {
            return (
                <div className='myInfo'>
                    <div className='info-box'>
                        <span className='step'>STEP 1</span> <span className='step-name'>회원정보</span>
                        <Row className='info'>
                            <Col className='relative' xs='12' sm='6'>
                                <div className='s-head'>아이디</div>
                                <div className='s-body'>{user.id}</div>
                            </Col>
                            <Col className='relative' xs='12' sm='6'>
                                <div className='s-head'>이름</div>
                                <div className='s-body'>{user.name}</div>
                            </Col>
                            <Col className='relative' xs='12' sm='6'>
                                <div className='s-head'>핸드폰</div>
                                <div className='s-body'>{user.phone}</div>
                            </Col>
                            <Col className='relative' xs='12' sm='6'>
                                <div className='s-head'>추천점</div>
                                <div className='s-body'>{user.recommender}</div>
                            </Col>
                            <Col className='relative' xs='12' sm='6'>
                                <div className='s-head'>총 보유 자산</div>
                                <div className='s-body'>{
                                    user && user.cash !== undefined ?
                                    user.cash.toLocaleString()
                                    :
                                    0} 원</div>
                            </Col>
                            <Col className='relative' xs='12' sm='6'>
                                <div className='s-head'>사용가능 자산</div>
                                <div className='s-body'>{
                                    user && user.cash !== undefined ?
                                    user.cash.toLocaleString()
                                    :
                                    0} 원</div>
                            </Col>
                        </Row>
                        <div className='wallet-edit'>
                            <label htmlFor='account-owner'>예금주</label>
                            <div className='relative'>
                                <input type='text' id='account-owner' placeholder='예금주' value={user.name} disabled />
                            </div>
        
                            <label htmlFor='bank'>은행명</label>
                            <div className='relative'>
                                <input type='text' id='bank' placeholder='은행명' value={user.bank} disabled />
                            </div>
        
                            <label htmlFor='account'>계좌번호</label>
                            <div className='relative'>
                                <input type='text' id='account' placeholder='계좌번호' value={user.account} disabled />
                            </div>
        
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Loading />
        }
    }
    
    //번호	거래일시	코인	구분	실현/실격	수량	보증금	결과	약정서
    
    orderByDateDesc = (a, b) => {
        if (a[1] < b[1]) {
            return 1;
        }
        if (a[1] > b[1]) {
            return -1;
        }
        // a must be equal to b
        return 0;
    };

    
    ExchangeHistory = () => {
        let thead = [
            { text: '번호', width: '10%' },
            { text: '거래일시', width: '20%' },
            { text: '코인', width: '10%' },
            { text: '구분', width: '10%' },
            { text: '실현/실격', width: '15%' },
            { text: '수량', width: '10%' },
            { text: '보증금', width: '15%' },
            { text: '결과', width: '10%' },
            // { text: '약정서', width: '10%' },
        ];
        
        /*
            [
                '1', '2021-03-17 15:37', '비트코인', '1분 매도', <span>1 USDT<img src='../img/icon_arr_updown.png' alt='up down' /></span>, '20 Lot', '100,000원', '실현', <button>보기</button>
            ],
        */

        let tbody = [];

        gameTypes.forEach(gameType => {
            intervals.forEach(interval => {
                  const histories = this.state.bettingHistories[gameType]['_' + interval];
                  histories.forEach(history => {
                    let statusText = '신청';
                    if (history.gameStatus === 3) {
                        if (history.isSuccess === 0) {
                            statusText = '실격';
                        } else {
                            statusText = '실현';
                        }
                    }

                    if (this.state.exchangeHistoryTypeSelected !== '전체' && this.state.exchangeHistoryTypeSelected !== statusText) {
                        return;
                    }

                    let now = new Date();
                    now.setHours(0, 0, 0, 0);
                    const dt = new Date(Date.parse(history.betDate));
                    console.log(dt);

                    switch (this.state.exchangeHistoryRangeSelected) {
                        case '당일':
                            if (dt < now) {
                                return;
                            }
                            break;
                        case '일주일':
                            now.setDate(now.getDate() - 7);
                            if (dt < now) {
                                return;
                            }
                            break;
                        case '1개월':
                            now.setDate(now.getDate() - 30);
                            if (dt < now) {
                                return;
                            }
                            break;
                        case '3개월':
                            now.setDate(now.getDate() - 90);
                            if (dt < now) {
                                return;
                            }
                            break;         
                        default:
                            break;
                    }

                    tbody.push([
                    history.betCode2, 
                    moment(history.betDate).utc().format('MM-DD HH:mm'),
                    gameType, 
                    interval + '분 매도', 
                    <span>1 USDT<img src='../img/icon_arr_updown.png' alt='up down' /></span>, 'NA Lot', 
                    parseInt(history.amount).toLocaleString() + '원', 
                    statusText, 
                    // <button>보기</button>
                    ]);
                  });
            });

            tbody = tbody.sort(this.orderByDateDesc);
            //this.state.bettingHistories
        });

        return (
            <div className='exchange-history'>
                <div className='type-btn-area'>
                    <button className={this.state.exchangeHistoryTypeSelected === '전체' ? 'selected' : ''} onClick={() => { this.changeExchangeHistoryType("전체") }}>전체</button>
                    <button className={this.state.exchangeHistoryTypeSelected === '실현' ? 'selected' : ''} onClick={() => { this.changeExchangeHistoryType("실현") }}>실현</button>
                    <button className={this.state.exchangeHistoryTypeSelected === '실격' ? 'selected' : ''} onClick={() => { this.changeExchangeHistoryType("실격") }}>실격</button>
                </div>
                <div className='date-btn-area'>
                    <button className={this.state.exchangeHistoryRangeSelected === '전체' ? 'selected' : ''} onClick={() => { this.setState({ exchangeHistoryRangeSelected: '전체' }); }}>전체</button>
                    <button className={this.state.exchangeHistoryRangeSelected === '당일' ? 'selected' : ''} onClick={() => { this.setState({ exchangeHistoryRangeSelected: '당일' }); }}>당일</button>
                    <button className={this.state.exchangeHistoryRangeSelected === '일주일' ? 'selected' : ''} onClick={() => { this.setState({ exchangeHistoryRangeSelected: '일주일' }); }}>일주일</button>
                    <button className={this.state.exchangeHistoryRangeSelected === '1개월' ? 'selected' : ''} onClick={() => { this.setState({ exchangeHistoryRangeSelected: '1개월' }); }}>1개월</button>
                    <button className={this.state.exchangeHistoryRangeSelected === '3개월' ? 'selected' : ''} onClick={() => { this.setState({ exchangeHistoryRangeSelected: '3개월' }); }}>3개월</button>
                </div>
                <Table thead={thead} tbody={tbody} />
            </div>
        );
    }

    changeExchangeHistoryType = (type) => {
        this.setState( {exchangeHistoryTypeSelected: type} );
    }
    
    Inquiry = () => {
        let thead = [
            { text: '번호', width: '10%' },
            { text: '제목', width: '60%' },
            { text: '작성자', width: '15%' },
            { text: '작성일', width: '15%' }
        ];

        return (
            <div className='inquiry'>
                <p>서비스 이용시 궁금한 사항이 있으시면 언제든지 문의바랍니다.</p>
                <p>신속하고 친절하게 답변드리겠습니다.</p>
                <Table thead={thead} tbody={this.state.inquiries} />
                {/* <div className="search">
                    <input type="text" placeholder="검색" />
                    <button className="btn_search"><img src="../img/icon_search.png" alt='검색' /></button>
                </div> */}
                <div className='button-area'>
                    <a href='/Board/Inquiry/Write'><Button color='primary'>문의하기</Button></a>
                    <Button color='danger' onClick={this.requestDepositAccount}>계좌요청하기</Button>
                </div>
            </div>
        );
    }
    
    Message = (type) => {
        let thead = [
            { text: '번호', width: '15%' },
            { text: '제목', width: '60%' },
            { text: '상태', width: '25%' }
        ];
        
        return (
            <div className='message'>
                <p>이벤트 및 지점별 주요 쪽지</p>
                <p>관리자로부터 회원님에게 직접 발송되는 쪽지입니다. 꼭 열람하십시오.</p>
                <Table key={type} thead={thead} tbody={this.state.messages} empty='현재 등록된 쪽지가 없습니다.' />
            </div>
        );
    }
    
    LoginHistory = (type) => {
        let thead = [
            { text: '시간', width: '25%' },
            { text: '디바이스', width: '25%' },
            { text: 'IP 주소', width: '25%' },
            { text: '상태', width: '25%' }
        ];
    
        let tbody = [
            // [ '2021.04.07\n03:40:43', 'Chrome 86 / Windows 10 / PC', '123.123.123.123', '성공' ],
            // [ '2021.04.07\n03:40:43', 'Chrome 86 / Windows 10 / PC', '123.123.123.123', '성공' ],
            // [ '2021.04.07\n03:40:43', 'Chrome 86 / Windows 10 / PC', '123.123.123.123', '성공' ]
        ];
        return (
            <div className='login-history'>
                <p>로그인 정보를 통해 해킹 시도 및 접속 위치을 확인하세요.</p>
                <p>자신의 접속반경 이외에 접속이 시도 되었다면 비밀번호 재설정 및 인증단계를 올려주세요.</p>
                <Table key={type} thead={thead} tbody={tbody} empty='로그인 내역 확인은 고객센터로 문의 바랍니다.' />
            </div>
        );
    }
    
    
    renderMyPage = (type) => {
        switch (type) {
            case 'Info':
                return this.Info(type);
            case 'ExchangeHistory':
                return this.ExchangeHistory(type);
            case 'Inquiry':
                return this.Inquiry(type);
            case 'Message':
                return this.Message(type);
            case 'LoginHistory':
                return this.LoginHistory(type);
            default:
                return <p>존재하지 않는 페이지입니다.</p>;
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = {
    
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyPage));