import { createStore } from 'redux'


export default createStore(
    (state, action) => {
        if (!state) {
            return { user: undefined };
        }
        switch (action.type) {
            case 'DECODETOKEN':
                return { ...state, user: action.user };    
            case 'GETBALANCE':
                return { ...state, user: {...state.user, cash: parseInt(action.balance.cash), point: parseInt(action.balance.point) } };
            default:
                break;
        }
        return state;
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);