import React, { useState } from 'react'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Modal} from 'react-bootstrap'; //Button, 
import Navigator from './Navigator';

export default function MobileNavigator(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <span>
      <FontAwesomeIcon onClick={handleShow} icon={faBars} color='white' fill='transparent' />
      <Modal show={show} onHide={handleClose} className='modal-mobile-navigator'>
        {/* <Modal.Header closeButton> */}
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        {/* </Modal.Header> */}
        <Modal.Body>
          <Navigator menu={props.menu}  className='mobile-navigator' linkClicked={handleClose.bind(this)} />
        </Modal.Body>
        {/* <Modal.Footer> */}
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        {/* </Modal.Footer> */}
      </Modal>
    </span>
  )
}
