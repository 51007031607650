import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import '../assets/scss/Login.scss';
import backgroundImage from '../assets/img/bg_customer.jpg';
// import { useHistory } from "react-router-dom";

class Login extends React.Component {
    login() {
        let id = document.getElementById('id').value;
        let password = document.getElementById('password').value;

        fetch('/api/user', {
            method: "POST",
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                id: id,
                password: password
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.token) {
                    localStorage.token = res.token;
                    localStorage.pub = res.pub;

                    window.location.href = '/';
                    // goHome();
                    // history.push('/');
                } else {
                    alert('아이디 혹은 비밀번호가 잘못되었습니다.');
                    document.getElementById('password').value = "";
                }
            });
    }

    render() {
        return (
            <div className='container login-area'>
                {/* <img src={backgroundImage} alt='login background' className='login-bg' /> */}
                <h1 className='login-header'>로그인</h1>
                <Row className='login-box'>
                    <Col>
                        <p>
                            <strong>S&P INDEX</strong>은 아이디, 비밀번호 저장 기능 사용을 권장하지 않습니다. PC방, 공용 인터넷 환경에서 고객정보 노출을 유의하세요.
                    </p>
                        <label htmlFor='id'>아이디</label>
                        <input id='id' name='id' type='text' placeholder='ID' />
                        <label htmlFor='password'>비밀번호</label>
                        <input id='password' name='password' type='password' placeholder='Password' />
                        <Button color='primary' onClick={this.login}>로그인</Button>
                        <Button color='danger' onClick={() => { window.location.href = '/Register'; }}>회원가입</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Login;