import React from 'react';
import '../assets/scss/Board.scss';
import moment from 'moment-timezone';
import iconSearch from '../assets/img/icon_search.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'


class Board extends React.Component {

    state = { notices: [] }

    componentDidMount() {
        if (localStorage.token === undefined || localStorage.token === null || localStorage.token === '') {
            window.location.href = '/Login';
        }
        
        fetch('/api/board?page=1&pageSize=20', {
            method: "GET",
            headers: {
                "Authorization": 'Bearer ' + localStorage.token
            }
        })
        .then(res => res.json())
        .then(res => {
            if (res.status === 'OK') {
                this.setState({ notices: res.data })
                //do something
            } else {
                //do something
            }
        }); //2021.03.24 16:32
        //.format('yyyy-MM-dd hh:mm');
    }

    render() {
        var boardId = this.props.match.params.boardId;
        // var articleId = this.props.match.params.articleId;

        //container board
        return (
            <div className='container board'>
                { this.renderBoard(boardId) }
            </div>
        );
    }


    renderBoard = (boardId) => {
        if (boardId === 'Notice') {
            return (
                <div className='board-list'>
                    {/* <div className="search">
                        <input type="text" placeholder="검색" />
                        <button className="btn_search"><img src={iconSearch} alt='검색' /></button>
                    </div> */}
                    <ul className="ul_border_list">
                        {
                            this.state.notices.map(notice => {
                                return (
                                    <li key={notice.idx}><a href={"/Board/Notice/" + notice.idx}><span className="no">{notice.RowNum}</span> <span>
                                        <div dangerouslySetInnerHTML={{__html: notice.title}}></div><span>{notice.nick}</span><b>{moment(notice.date).utc().format('yyyy-MM-DD HH:mm')}</b></span></a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            );
        } else {
            window.location.href = '/';
        }
    }

    //http://127.0.0.1:3000/Board/Notice/502747
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Board));