import React, { Component } from 'react'
import { Col } from 'reactstrap';
import '../assets/scss/GuideStep.scss';

export default class GuideStep extends Component {
    render() {
        return (
            <Col className='guide-step' xs='12' sm='6'>
                <span className='no'>{this.props.step}</span>
                <span className='name'>{this.props.stepName}</span>
                <div className='content'>
                    {this.props.content}
                </div>
                <div className='description'>
                    {this.props.description}
                </div>
            </Col>
        )
    }
}
