import React from "react";
import {
    Switch,
    Route as Use
  } from "react-router-dom";
  import Home from './pages/Home';
  import Guide from './pages/Guide';
  import Exchange from './pages/Exchange';
  import Wallet from './pages/Wallet';
  import Board from './pages/Board';
  import Info from './pages/Info';
  import MyPage from './pages/MyPage';
  import Login from './pages/Login';
import BoardView from "./pages/BoardView";
import BoardWrite from "./pages/BoardWrite";
import Register from './pages/Register';

export default function Route() {
    return (
        <Switch>
            <Use exact path="/">
                <Home />
            </Use>
            <Use path="/Guide/:guideType">
                <Guide />
            </Use>
            <Use path="/Exchange/:gameType">
                <Exchange />
            </Use>
            <Use path="/Wallet/:walletMode">
                <Wallet />
            </Use>
            <Use path="/Board/:boardId/Write">
                <BoardWrite />
            </Use>
            <Use path="/Board/:boardId/:articleId">
                <BoardView />
            </Use>
            <Use path="/Board/:boardId">
                <Board />
            </Use>   
            <Use path="/Info/:infoType">
                <Info />
            </Use>
            <Use path="/MyPage/:mypageType">
                <MyPage />
            </Use>
            <Use path="/Login">
                <Login />
            </Use>
            <Use path="/Register">
                <Register />
            </Use>
        </Switch>
    );
}