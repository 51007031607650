import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../assets/scss/Tabs.scss';

export default class Tabs extends Component {
    constructor(props) {
        super(props);
        this.className = this.props.className ? this.props.className : '';
    }

    render() {
        return (
            <div className={'db-tabs ' + this.className}>
                <ul className='container'>
                    {
                        Object.keys(this.props.tabData).map(key => {
                            let selected = this.props.selected === key ? 'selected' : '';
                            return (
                                <li className={selected} key={key}><Link to={this.props.path.replace(this.props.wildCard, key)}>{this.props.tabData[key].title}</Link></li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}
