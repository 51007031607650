import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../assets/scss/MyPage.scss';
import iconPwOn from '../assets/img/icon_pw_on.png';
import iconPwOff from '../assets/img/icon_pw_off.png';
import { Button } from 'reactstrap';

export default class Register extends Component {
    state = {
        passwordVerifiedIcon: iconPwOff
    };

    constructor(props) {
        super(props);

        this.idRef = React.createRef();
        this.recommenderRef = React.createRef();
        this.nickRef = React.createRef();
        this.passwordRef = React.createRef();
        this.passwordRepeatRef = React.createRef();
        this.withdrawPasswordRef = React.createRef();
        this.withdrawPasswordRef.current = "1234";
        this.bankRef = React.createRef();
        this.nameRef = React.createRef();
        this.accountRef = React.createRef();
        this.phoneRef = React.createRef();
        
    }

    passwordHandler = () => {
        const password = this.passwordRef.current.value, 
        passwordRepeat = this.passwordRepeatRef.current.value;

        if (password.length >= 6 && password === passwordRepeat) {
            this.setState({ passwordVerifiedIcon: iconPwOn });
        } else {
            this.setState({ passwordVerifiedIcon: iconPwOff });
        }
    }

    join = () => {
        const 
        password = this.passwordRef.current.value, 
        passwordRepeat = this.passwordRepeatRef.current.value,
        withdrawPassword = "1234",
        id = this.idRef.current.value,
        nick = this.nickRef.current.value,
        bank = this.bankRef.current.value,
        name = this.nameRef.current.value,
        account = this.accountRef.current.value,
        phone = this.phoneRef.current.value,
        recommender = this.recommenderRef.current.value;//this.withdrawPasswordRef.current.value,

        if (id.length < 2) {
            alert('아이디가 너무 짧습니다.');
            return;
        }

        if (id.length > 20) {
            alert('아이디가 너무 깁니다.');
            return;
        }

        if (recommender.length < 2 || recommender.length > 20) {
            alert('추천점를 제대로 입력해주세요.');
            return;
        }

        if (nick.length < 2) {
            alert('닉네임이 너무 짧습니다.');
            return;
        }

        if (nick.length > 20) {
            alert('닉네임이 너무 깁니다.');
            return;
        }

        if (password.length < 4) {
            alert('비밀번호의 길이가 너무 짧습니다.');
            return;
        }

        if (password.length > 20) {
            alert('비밀번호의 길이가 너무 깁니다.');
            return;
        }

        if (password !== passwordRepeat) {
            alert('비밀번호 확인이 맞지 않습니다.');
            return;
        }

        if (withdrawPassword.length < 4) {
            alert('출금 비밀번호의 길이가 너무 짧습니다.');
            return;
        }

        if (withdrawPassword.length > 20) {
            alert('출금 비밀번호의 길이가 너무 깁니다.');
            return;
        }

        if (bank.length < 2 || bank.length > 30) {
            alert('은행을 제대로 입력해주세요.');
            return;
        }

        if (name.length < 2 || name.length > 20) {
            alert('이름을 제대로 입력해주세요.');
            return;
        }

        if (account.length < 4 || account.length > 30) {
            alert('계좌번호를 제대로 입력해주세요.');
            return;
        }

        if (phone.length < 10 || phone.length > 30) {
            alert('전화번호를 제대로 입력해주세요');
            return;
        }
    

        fetch('/api/user/join', {
            method: "POST",
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                id: id,
                password: password,
                passwordRepeat: passwordRepeat,
                recommender: recommender,
                withdrawPassword: withdrawPassword,
                nick: nick,
                bank: bank,
                account: account,
                phone: phone,
                name: name
            })
        })
        .then(res => res.json())
        .then(res => {
            if (res.status === 'OK') {
                alert('회원가입에 성공했습니다.');
                window.location.href = '/';
            } else {
                alert(res.message);
            }
        });
                
    }

    render() {
        return (
            <div className='container myPage register'>
                <div className='myInfo'>
                    <div className='info-box'>
                        <span className='step-name'>회원가입</span>
                        <div className='info-edit'>
                            <label htmlFor='recommender'>추천점</label>
                            <div className='relative'>
                                <input type='text' id='recommender' placeholder='추천점' ref={this.recommenderRef} />
                            </div>
                            <label htmlFor='id'>아이디</label>
                            <div className='relative'>
                                <input type='text' id='id' placeholder='아이디' ref={this.idRef} />
                            </div>
                            <label htmlFor='nick'>닉네임</label>
                            <div className='relative'>
                                <input type='text' id='nick' placeholder='닉네임' ref={this.nickRef} />
                            </div>
                            <label htmlFor='password'>비밀번호</label>
                            <div className='relative'>
                                <input type='password' id='password' placeholder='비밀번호' ref={this.passwordRef} onChange={this.passwordHandler} />
                            </div>
                            <label htmlFor='password-repeat'>비밀번호 재입력</label>
                            <div className='relative'>
                                <input type='password' id='password-repeat' placeholder='비밀번호 재입력' ref={this.passwordRepeatRef} onChange={this.passwordHandler} />
                                {/* <img src={this.state.passwordVerifiedIcon} alt='비밀번호 확인' /> */}
                            </div>

                            <label htmlFor='withdraw-password' style={{ display: 'none' }}>출금 비밀번호</label>
                            <div className='relative' style={{ display: 'none' }}>
                                <input type='text' id='withdraw-password' placeholder='출금 비밀번호' ref={this.withdrawPasswordRef} />
                            </div>
                            <label htmlFor='bank'>은행</label>
                            <div className='relative'>
                                <input type='text' id='bank' placeholder='은행' ref={this.bankRef} />
                            </div>
                            <label htmlFor='name'>이름</label>
                            <div className='relative'>
                                <input type='text' id='name' placeholder='이름' ref={this.nameRef} />
                            </div>
                            <label htmlFor='account'>계좌번호</label>
                            <div className='relative'>
                                <input type='text' id='account' placeholder='계좌번호' ref={this.accountRef} />
                            </div>
                            <label htmlFor='phone'>전화번호</label>
                            <div className='relative'>
                                <input type='text' id='phone' placeholder='전화번호' ref={this.phoneRef} />
                            </div>
                            <Button className='bootstrap-btn' color="primary" size='block' onClick={this.join.bind(this)}>회원가입</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
