import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap';
import '../assets/scss/Board.scss';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUser } from '@fortawesome/free-solid-svg-icons';
import Loading from '../components/Loading';

class BoardView extends Component {
    state = {
        article: undefined
    }

    componentDidMount() {
        var boardId = this.props.match.params.boardId;
        var articleId = this.props.match.params.articleId;

        if (boardId === 'Notice') {
            fetch('/api/board/' + articleId, {
                method: "GET",
                headers: {
                    "Authorization": 'Bearer ' + localStorage.token
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status === 'OK') {
                        this.setState({ article: res.data });
                    } else {
                        window.location.href = '/';
                    }
                });
        } else if (boardId === 'Inquiry') {
            fetch('/api/contactus/' + articleId, {
                method: "GET",
                headers: {
                    "Authorization": 'Bearer ' + localStorage.token
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status === 'OK') {
                        this.setState({ article: res.data });
                    } else {
                        window.location.href = '/';
                    }
                });
        }
    }

    render() {
        return (
            <div className='container board board-view'>
                {
                    this.state.article ?
                        <div>
                            <h1 dangerouslySetInnerHTML={{ __html: this.state.article.title }}></h1>
                            <div className='info'><span className='nick'><FontAwesomeIcon icon={faUser} color='#688b9c' /> {this.state.article.nick}</span><span className='date'><FontAwesomeIcon icon={faClock} color='#688b9c' /> {moment(this.state.article.date).utc().format('yyyy.MM.DD HH:mm')}</span></div>
                            <div dangerouslySetInnerHTML={{ __html: this.state.article.content }} className='content'>
                            </div>
                            <div className='comments'>
                                {
                                    this.state.article.comments ? 
                                    this.state.article.comments.map(comment => {
                                        return (
                                        <div className='comment' key={comment.idx}>
                                            <div className='c_prefix'>└</div>
                                            <div className='c_content' dangerouslySetInnerHTML={{ __html: comment.content }} ></div>
                                            <div className='c_info'>{comment.nick}</div>
                                        </div>
                                        )
                                    })
                                    :
                                    ''
                                }
                            </div>
                            <Button size='md' onClick={() => { window.history.back() }}>목록</Button>
                        </div>
                        :
                        <Loading />
                }
            </div>
        )
    }
}


const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BoardView))